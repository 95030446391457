/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from "react";
import { Space, Swiper } from "antd-mobile";
import styles from "../assets/index.module.css";
import "../assets/index.css";
import { Account } from ".";
import { Link,useHistory} from "react-router-dom";
import { Button, message } from 'antd';
export default function Userheader() {
  const [menuShow, setMenuShow] = React.useState(false);
  const [MetaMaskshow, setMetaMaskshow] = React.useState(false);
  const [artists, setArtists] = React.useState([]);
  const history = useHistory()
  let props = {};
      props.data =  React.$userinfo
  const state = {
    isphone: false,
    MetaMaskshow: false,
  };
  function browserRedirect() {
    var sUserAgent = navigator.userAgent.toLowerCase();
    var bIsIpad = sUserAgent.match(/ipad/i) == "ipad";
    var bIsIphoneOs = sUserAgent.match(/iphone os/i) == "iphone os";
    var bIsMidp = sUserAgent.match(/midp/i) == "midp";
    var bIsUc7 = sUserAgent.match(/rv:1.2.3.4/i) == "rv:1.2.3.4";
    var bIsUc = sUserAgent.match(/ucweb/i) == "ucweb";
    var bIsAndroid = sUserAgent.match(/android/i) == "android";
    var bIsCE = sUserAgent.match(/windows ce/i) == "windows ce";
    var bIsWM = sUserAgent.match(/windows mobile/i) == "windows mobile";
    if (
      bIsIpad ||
      bIsIphoneOs ||
      bIsMidp ||
      bIsUc7 ||
      bIsUc ||
      bIsAndroid ||
      bIsCE ||
      bIsWM
    ) {
      //跳转移动端页面
      state.isphone = true;
    } else {
      //跳转pc端页面
      state.isphone = false;
    }
  }
  browserRedirect();
  function backhome() {
    history.push({ pathname: '/', state: {} })
  }

  return (
    // ${styles["nav-bar_1"]}
    <div className={"container-fluid"}>
   <div style={{ display: state.isphone ? "block" : "none" }} className={`${styles["user_footer"]}`}>
          <div className={`flex-col`}>
            <div className={`${styles["image-wrapper_2"]} flex-row`}>
              <img
                className={`${styles["image_9"]}`}
                src={require("../assets/image/logo2.png").default}
              />
            </div>
            <div className={`${styles["image-wrapper_3"]} flex-row`}>
              <a href="https://t.me/AIWORLD0765"  target="_blank">
                <img
                  className={`${styles["icon_1-0"]}`}
                  src={
                    require("../assets/image/sj1.png").default
                  }
                />
              </a>
              <a href="https://medium.com/@aiworld0765"  target="_blank">
                <img
                  className={`${styles["icon_1-1"]}`}
                  src={
                    require("../assets/image/sj2.png").default
                  }
                />
              </a>
              <a href="https://www.youtube.com/@AIWORLD-0765/videos"  target="_blank">
                <img
                  className={`${styles["icon_1-2"]}`}
                  src={
                    require("../assets/image/ytb.png").default
                  }
                />
              </a>
              <a href="https://twitter.com/AIWORLD_HQ"  target="_blank">
                <img
                  className={`${styles["icon_1-3"]}`}
                  src={
                    require("../assets/image/sj4.png").default
                  }
                />
              </a>
            </div>
          </div>
          <div className={`${styles["block_7"]} flex-col`}>
            <div
              className={`${styles["text-wrapper_11"]} flex-row justify-between`}
            >
              <a className={`${styles["text_52"]} ${styles["text_color"]}`}>
                {React.$i18n('footer.About')}
              </a>
              <a className={`${styles["text_53"]} ${styles["text_color"]}`}>
               {React.$i18n('footer.Community')}
              </a>
            </div>
            <div
              className={`${styles["text-wrapper_12"]} flex-row justify-between`}
            >
              <a className={`${styles["text_54"]} ${styles["text_color"]}`} onClick={()=>{
                     history.push({ pathname: "/Nodes", state: {} })
              }}>
                 {React.$i18n('footer.Nodes')}
              </a>
              <a className={`${styles["text_55"]} ${styles["text_color"]}`} href="https://t.me/AIWORLD0765"  target="_blank">
                 {React.$i18n('footer.Telegram')}
              </a>
            </div>
            <div
              className={`${styles["text-wrapper_13"]} flex-row justify-between`}
            >
              <a className={`${styles["text_56"]} ${styles["text_color"]}`} onClick={()=>{
                          history.push({ pathname: "/MyNodes", state: {} })
              }}>
                  {React.$i18n('footer.MyNodes')}
              </a>
              <a className={`${styles["text_57"]} ${styles["text_color"]}`}  href="https://medium.com/@aiworld0765"  target="_blank">
              {React.$i18n('footer.Medium')} 
              </a>
            </div> 
            <div
              className={`${styles["text-wrapper_14"]} flex-row justify-between`}
            >
              <a href="https://globalchats-organization.gitbook.io/aiworld-whitepaper-1/" target="_blank" className={`${styles["text_58"]} ${styles["text_color"]}`}>
               {React.$i18n('footer.Whitepaper')} 
              </a>
              <a className={`${styles["text_59"]} ${styles["text_color"]}`}  href="https://t.me/AIWORLDcommunity"  target="_blank">
                 {React.$i18n('footer.Discord')} 
              </a>
            </div>
            <div className={`${styles["text-wrapper_15"]} flex-row`}>
            <a href="/Security.pdf" target="_blank" className={`${styles["Security"]} ${styles["text_color"]}`}>
                {React.$i18n('footer.Security')}
            </a>
              <a className={`${styles["text_60"]} ${styles["text_color"]}`}  href="https://twitter.com/TTI20000101"  target="_blank">
                  {React.$i18n('footer.Twitter')}
              </a>
            </div>
            <div className={`${styles["text-wrapper_15"]} flex-row`}>
            <a   onClick={() => {history.push({ pathname: "/Publicoffering", state: {} }); }}  className={`${styles["Security"]} ${styles["text_color"]}`}>
            {React.$i18n('AiwordGlobalL.PUBLICOFFER')}
            </a>
            <a className={`${styles["text_60"]} ${styles["text_color"]}`} href="https://alt.jotfor.ms/231062650799461"  target="_blank">
              {React.$i18n('footer.Partnership')}
            </a>
          </div>
          </div>
        </div>

     <div
        className={`${styles["lastfooter"]} flex-row  justify-between`}
        style={{ display: state.isphone ? "none" : "block" }}
      >
        <div className={`${styles["footerleft"]}`}>
          <div className={`${styles["image-wrapper_2"]} flex-row`}>
            <img
              className={`${styles["image_9"]}`}
              src={require("../assets/image/logo2.png").default}
            />
          </div>
          <div className={`${styles["image-wrapper_3"]} flex-row`}>
            <a href="https://t.me/AIWORLD0765"  target="_blank">
              <img
                className={`${styles["icon_1-0"]}`}
                src={
                  require("../assets/image/sj1.png").default
                }
              />
            </a>
            <a href="https://medium.com/@aiworld0765"  target="_blank">
             <img
                className={`${styles["icon_1-1"]}`}
                src={
                  require("../assets/image/sj2.png").default
                }
              />
            </a>
            <a href="https://www.youtube.com/@AIWORLD-0765/videos"  target="_blank">
                <img
                  className={`${styles["icon_1-2"]}`}
                  src={
                    require("../assets/image/ytb.png").default
                  }
                />
              </a>
            <a href="https://twitter.com/AIWORLD_HQ"  target="_blank">
            <img
                className={`${styles["icon_1-3"]}`}
                src={
                  require("../assets/image/sj4.png").default
                }
              />
            </a>
          </div>
        </div>
        <div className={`${styles["footerright"]} flex-col`}>
          <div
            className={`${styles["text-wrapper_11"]} flex-row justify-between`}
          >
            <a className={`${styles["text_52"]} ${styles["text_color"]}`}>
              {React.$i18n('footer.About')}
            </a>
            <a className={`${styles["text_53"]} ${styles["text_color"]}`}>
             {React.$i18n('footer.Community')}
            </a>
          </div>
          <div
            className={`${styles["text-wrapper_12"]} flex-row justify-between`}
          >
            <a className={`${styles["text_54"]} ${styles["text_color"]}`} onClick={()=>{
                      history.push({ pathname: "/Nodes", state: {} })
              }}>
                {React.$i18n('footer.Nodes')}
            </a>
            <a className={`${styles["text_55"]} ${styles["text_color"]}`}  href="https://t.me/AIWORLD0765"  target="_blank">
              {React.$i18n('footer.Telegram')}
            </a>
          </div>
          <div
            className={`${styles["text-wrapper_13"]} flex-row justify-between`}
          >
            <a className={`${styles["text_56"]} ${styles["text_color"]}`} onClick={()=>{
                   history.push({ pathname: "/MyNodes", state: {} })
              }}>
              {React.$i18n('footer.MyNodes')}
            </a>
            <a className={`${styles["text_57"]} ${styles["text_color"]}`} href="https://medium.com/@aiworld0765"  target="_blank">
                {React.$i18n('footer.Medium')}
            </a>
          </div>
          <div
            className={`${styles["text-wrapper_14"]} flex-row justify-between`}
          >
            <a href="https://globalchats-organization.gitbook.io/aiworld-whitepaper-1/" target="_blank" className={`${styles["text_58"]} ${styles["text_color"]}`}>
             {React.$i18n('footer.Whitepaper')}
            </a> 
            <a className={`${styles["text_59"]} ${styles["text_color"]}`}  href=" https://t.me/AIWORLDcommunity"  target="_blank">
               {React.$i18n('footer.Discord')}
            </a>
          </div>

          <div className={`${styles["text-wrapper_15"]} flex-row`}>
          <a href="/Security.pdf" target="_blank" className={`${styles["Security"]} ${styles["text_color"]}`}>
                {React.$i18n('footer.Security')}
            </a>
            <a className={`${styles["text_60"]} ${styles["text_color"]}`} href="https://twitter.com/TTI20000101"  target="_blank">
               {React.$i18n('footer.Twitter')}
            </a>
          </div>

          <div className={`${styles["text-wrapper_15"]} flex-row`}>
            <a   onClick={() => {history.push({ pathname: "/Publicoffering", state: {} }); }}  className={`${styles["Security"]} ${styles["text_color"]}`}>
            {React.$i18n('AiwordGlobalL.PUBLICOFFER')}
            </a>
            <a className={`${styles["text_60"]} ${styles["text_color"]}`} href="https://alt.jotfor.ms/231062650799461"  target="_blank">
               {React.$i18n('footer.Partnership')}
            </a>
          </div>


        </div>
      </div>

    </div>
  );
}

