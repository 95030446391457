import React, { Component, useEffect } from "react";
import { Space, Swiper } from "antd-mobile";
import * as bootstrap from "react-bootstrap";
import styles from "../assets/css/UserWallet.module.css";
import "../assets/css/buynodes.css";
import { Link, useHistory } from "react-router-dom";
import { UsersHeader, UsersFooter } from "../components";
import { Contract } from "ethers";
import NFT_ABI from "../contracts/ainft_contract";
import { CLOUD_API_URL, UPLOAD_IMAGE_URL } from "../constants";
import AI_NEW_NODE_ABI from "../contracts/ainewnode_contracts";
import { ERC20ABI } from "../contracts/external_contracts";
import { Button, message, Radio, InputNumber, Modal} from "antd";

import { utils } from "ethers";

function BuyNodes(props) {
  const state = {
    isphone: false,
    MetaMaskshow: false,
  };
  function browserRedirect() {
    var sUserAgent = navigator.userAgent.toLowerCase();
    var bIsIpad = sUserAgent.match(/ipad/i) == "ipad";
    var bIsIphoneOs = sUserAgent.match(/iphone os/i) == "iphone os";
    var bIsMidp = sUserAgent.match(/midp/i) == "midp";
    var bIsUc7 = sUserAgent.match(/rv:1.2.3.4/i) == "rv:1.2.3.4";
    var bIsUc = sUserAgent.match(/ucweb/i) == "ucweb";
    var bIsAndroid = sUserAgent.match(/android/i) == "android";
    var bIsCE = sUserAgent.match(/windows ce/i) == "windows ce";
    var bIsWM = sUserAgent.match(/windows mobile/i) == "windows mobile";
    if (
      bIsIpad ||
      bIsIphoneOs ||
      bIsMidp ||
      bIsUc7 ||
      bIsUc ||
      bIsAndroid ||
      bIsCE ||
      bIsWM
    ) {
      //跳转移动端页面
      state.isphone = true;
    } else {
      //跳转pc端页面
      state.isphone = false;
    }
  }
  browserRedirect();

  const history = useHistory();
  function back() {
    history.push({ pathname: "/UserSignIn", state: {} });
  }
  const [expandedIndex, setExpandedIndex] = React.useState(-1);
  const items = [
    {
      title: React.$i18n("Nodes.itemstitle1"),
      content: React.$i18n("Nodes.itemscontent1"),
    },
    {
      title: React.$i18n("Nodes.itemstitle2"),
      content: React.$i18n("Nodes.itemscontent2"),
    },
    {
      title: React.$i18n("Nodes.itemstitle3"),
      content: React.$i18n("Nodes.itemscontent3"),
    },
    {
      title: React.$i18n("Nodes.itemstitle4"),
      content: React.$i18n("Nodes.itemscontent4"),
    },
    {
      title: React.$i18n("Nodes.itemstitle5"),
      content: React.$i18n("Nodes.itemscontent5"),
    },
    // { title: React.$i18n('Nodes.itemstitle6'),content:React.$i18n('Nodes.itemscontent6')},
  ];

  const [progress, setProgress] = React.useState(0);

  function back() {
    history.push({ pathname: "/Nodes", state: {} });
  }
  const [status, setStatus] = React.useState("loading");
  const [showMessage, setShowMessage] = React.useState(false);
  const [soldNum, setsoldNum] = React.useState(0);
  const [showprice, setshowprice] = React.useState(0); //展示的节点价格
  const [radiovalue, seradiovalue] = React.useState(0); // node type 0: normal, 1: main, 2: cluster
  const [price, setprice] = React.useState(0); //正常节点价格
  const [minprice, setminprice] = React.useState(5000); //主节点价格
  const [classprice, setclassprice] = React.useState(10000); //集群节点价格
  const [refNodeId, setrefNodeId] = React.useState(0); // pass ref node id
  const [nodecontent, setcontent] = React.useState(React.$i18n("Nodes.node1")); // 切换节点 介绍
  const [isModalOpen, setIsModalOpen] = React.useState(false);  //二次确认

  const tx = props.data.tx;

  let address =
    props.data.address ||
    React.$userinfo.address ||
    sessionStorage.getItem("address");
  const usdtAddress = props.data.usdtAddress;
  const contractAddress = props.data.contractAddress;

  async function getnodes() {
    const referAddress = "";
    if (!props.data.mainnetProvider || !props.data.userSigner) {
      setStatus("Please connect your wallet first.");
      return;
    }
    const contract = new Contract(
      contractAddress,
      AI_NEW_NODE_ABI,
      props.data.userSigner
    );
    const soldNum = await contract.nodesSold(); //卖出多少个node in total
    const nomalNodeSold = await contract.normalNodesSold(); // how many normal nodes always +3500 airdropped node
    const priceOfClusterNode = "10000"; // 集群节点 price
    const priceOfMainNode = "5000"; //  主要节点 price
    const priceOfNode = await contract.normalNodePrice(); //价格

    let a = parseInt(soldNum._hex, 16);
    let b = parseInt(priceOfNode._hex, 16) / 1000000000000000000;
    let bfb = (soldNum / 20000).toFixed(1);
    if (bfb < 100) {
      setProgress(bfb);
    }
    setsoldNum(a);
    setprice(b);

    setminprice(priceOfMainNode); //主节点价格
    setclassprice(priceOfClusterNode); //集群节点价格
    setshowprice(b); //显示价格
  }

  async function Buynodes() {
    if (
      address == "" ||
      address == null ||
      address == undefined ||
      address == "undefined"
    ) {
      setStatus("please log in first", address);
      setShowMessage(true);
      return;
    }
    setStatus("loading...");
    setShowMessage(true);
    if (!props.data.mainnetProvider || !props.data.userSigner) {
      setStatus("Please connect your wallet first.");
      return;
    }
    // const referAddress = React.$refAddress || "";
    const usdtContract = new Contract(
      usdtAddress,
      ERC20ABI,
      props.data.userSigner
    );

    const contract = new Contract(
      contractAddress,
      AI_NEW_NODE_ABI,
      props.data.userSigner
    );

    const allowance = await usdtContract.allowance(address, contractAddress);
    console.log(allowance);
       
    
    let linum =  showprice 
      if(radiovalue==0){
          linum = Number(showprice.toFixed(0)) + 1;
      }else{
          linum = showprice
      }
  
 
    const priceOfNode = utils.parseEther(linum + ""); // convert to string first
    if (priceOfNode.gt(allowance)) {
      console.log("not enough allowance");
      // const approveUsdtTx = await usdtContract.approve(
      //   contractAddress,
      //   priceOfNode
      // );
      // await approveUsdtTx.wait();
      const result = await usdtContract.approve(contractAddress, priceOfNode);
      console.log("awaiting metamask/web3 confirm result...", result);
      console.log(await result);
    }

    const buyResult = await contract.buyNode(
      radiovalue,
      priceOfNode,
      refNodeId
    );
    console.log("awaiting metamask/web3 confirm result...", buyResult);
    console.log(await buyResult);
  }

  const onChange = (e) => {
    //  console.log('radio checked', e.target.value);
    seradiovalue(e.target.value);
    if (e.target.value == 0) {
      setshowprice(price);
      setcontent(React.$i18n("Nodes.node1"))
    } else if (e.target.value == 1) {
      setshowprice(minprice);
      setcontent(React.$i18n("Nodes.node2"))
    } else {
      setshowprice(classprice);
      setcontent(React.$i18n("Nodes.node3"))
    }
  };

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = async () => {
    setIsModalOpen(false);
    await  Buynodes();
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };


  React.useEffect(() => {
    if (showMessage) {
      setTimeout(() => {
        setShowMessage(false);
      }, 4000);
    }
  }, [showMessage]);

  React.useEffect(() => {
    getnodes();
  }, [props.data.mainnetProvider, props.data.address]);


  const [days, setDays] = React.useState(0);
  const [hours, setHours] = React.useState(0);
  const [minutes, setMinutes] = React.useState(0);
  const [seconds, setSeconds] = React.useState(0);
  const [countdownfalg, setcountdownfalg] = React.useState(true);
  useEffect(() => {
    const countdownDate = new Date("July 29, 2023 15:00:00").getTime();
    const interval = setInterval(() => {
      const now = new Date().getTime();
      const distance = countdownDate - now;
      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);
      setDays(days);
      setHours(hours);
      setMinutes(minutes);
      setSeconds(seconds);
      if (distance < 0) {
        //倒计时结束
        setcountdownfalg(true);
        clearInterval(interval);
        setDays(0);
        setHours(0);
        setMinutes(0);
        setSeconds(0);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);



  return (
    <div className={`${styles["container"]}`}>
      <UsersHeader></UsersHeader>

      {showMessage && <div className={`${styles["popup"]}`}>{status}</div>}

      <div
        style={{ display: state.isphone ? "none" : "block" }}
        className={`${styles["backUserSignIn"]}`}
        onClick={() => {
          back();
        }}
      >
        {" "}
        {"<  back"}{" "}
      </div>
      <div className={`${styles["mynodebody"]}`}>


      {!countdownfalg?<div  className={`${styles["buynode-mc"]}`}>
          <div className={`${styles["PointsBox-bindbox"]}`}>
        
            <div class="UserIntest">
              <div className={`${styles["PointsBox-title"]}`}>  
                {React.$i18n('BuyNodes.Countdown')}
             
              </div>
                 <div class="UserIntest">
                   {/* <div className={`${styles["Countdownin"]}`}>Countdown in</div> */}
                   <div className={`${styles["countdownDate"]}`}>
                     <div className={`${styles["Date-list"]}`}>
                       <div className={`${styles["Date-listtop"]}`}>{days}</div>
                       <div className={`${styles["Date-listbot"]}`}>{React.$i18n("BuyNodes.DAYS")}</div>
                     </div>
                     <div className={`${styles["colon"]}`}> :</div>
                     <div className={`${styles["Date-list"]}`}>
                       <div className={`${styles["Date-listtop"]}`}>{hours}</div>
                       <div className={`${styles["Date-listbot"]}`}> {React.$i18n("BuyNodes.HOURS")}</div>
                     </div>
                     <div className={`${styles["colon"]}`}> :</div>
                     <div className={`${styles["Date-list"]}`}>
                       <div className={`${styles["Date-listtop"]}`}>
                         {minutes}
                       </div>
                       <div className={`${styles["Date-listbot"]}`}> {React.$i18n("BuyNodes.MINUTES")}</div>
                     </div>
                     <div className={`${styles["colon"]}`}> :</div>
                     <div className={`${styles["Date-list"]}`}>
                       <div className={`${styles["Date-listtop"]}`}>
                         {seconds}
                       </div>
                       <div className={`${styles["Date-listbot"]}`}> {React.$i18n("BuyNodes.SECONDS")}</div>
                     </div>
                   </div>
                 </div>
                <div>
              </div>
            </div>
          </div>
        </div>
     :""}

        <div className={`${styles["bugnodeheader"]}`}>
          {radiovalue == 0 ? (
            <img
              className={`${styles["bugheader"]}`}
              src={require(`../assets/image/bug.png`).default}
            />
          ) : radiovalue == 1 ? (
            <img
              className={`${styles["bugheader"]}`}
              src={require(`../assets/image/minnode.png`).default}
            />
          ) : (
            <img
              className={`${styles["bugheader"]}`}
              src={require(`../assets/image/classnode.png`).default}
            />
          )}

          <div className={`${styles["bugAIWorld"]}`}>
            <div className={`${styles["bugnodestitle"]}`}>
              {React.$i18n("Nodes.itemstitle1")}
            </div>
            <div className={`${styles["bugnodesdes"]}`}>
              {nodecontent}
            </div>
            <div className={`${styles["progressBar"]}`}>
              <div
                className={`${styles["progress"]}`}
                style={{ width: `${progress}%` }}
              ></div>
            </div>
            <div className={`${styles["SoldNodes"]}`}>
              {React.$i18n("BuyNodes.SoldNodes")}
              {soldNum}
            </div>
            <Radio.Group
              className={`${styles["RadioGroup"]}`}
              onChange={onChange}
              value={radiovalue}
            >
              <Radio className={`${styles["Radio"]}`} value={0}>
                {React.$i18n("BuyNodes.node")}
              </Radio>
              <Radio className={`${styles["Radio"]}`} value={1}>
                {React.$i18n("BuyNodes.MasterNode")}
              </Radio>
              <Radio className={`${styles["Radio"]}`} value={2}>
                {React.$i18n("BuyNodes.ClusterNode")}
              </Radio>
            </Radio.Group>
            <div className={`${styles["bugnodesPrice"]}`}>
              <div className={`${styles["bugleft"]}`}>
                {React.$i18n("BuyNodes.Price")}
              </div>
              <div className={`${styles["bugright"]}`}>
                <img
                  className={`${styles["bugbz"]}`}
                  src={require(`../assets/image/bz.png`).default}
                />
                {showprice || "--"}U
              </div>
            </div>

            <div className={`${styles["bugnodesPrice"]}`}>
              <div className={`${styles["bugleft"]}`}>
                {React.$i18n("BuyNodes.ReferrerAddress")}
              </div>
              <div className={`${styles["bugright"]}`}>
                <InputNumber
                  className={`${styles["nodesinput"]}`}
                  controls={false}
                  min={1}
                  placeholder={React.$i18n("BuyNodes.pltip")}
                  onChange={(e) => {
                    setrefNodeId(e);
                  }}
                />
                {/* <img
                  className={`${styles["bugbz"]}`}
                  src={require(`../assets/image/bz.png`).default}
                />
                <span className={`${styles["refAddress"]}`}>
                  {React.$refAddress || "--"}
                </span> */}
              </div>
            </div>

            <button
              className={`${styles["UserInvBtn"]}`}
              onClick={async () => {
                if (countdownfalg) {
                 if(refNodeId && refNodeId>0){
                    showModal()
                   }else{
                      await  Buynodes();
                   }
                                
                } else {
                message.open({
                  type: "warning",
                  content: React.$i18n("BuyNodes.waritime"),
                  className: "userTip-mess",
                  duration: 3,
                });
                }
              }}
            >
              {React.$i18n("BuyNodes.PURCHASENODE")}
            </button>
          </div>
        </div>

      <Modal title={React.$i18n("BuyNodes.nodetitle")} open={isModalOpen} cancelText={React.$i18n("BuyNodes.Cancel")} okText={React.$i18n("BuyNodes.confirm")} onOk={handleOk} onCancel={handleCancel}>
       {React.$i18n("BuyNodes.nodeTIp")}
      </Modal>


        <div className={`${styles["bugnodeline"]}`}></div>
        <img
          className={`${styles["node2header"]}`}
          src={require(`../assets/image/tnodeitlepc.png`).default}
        />
        <div className={`${styles["problemList"]}`}>
          {
            <ul className={`${styles["problem_ul"]}`}>
              {items.map((item, index) => (
                <li className={`${styles["problem_li"]}`} key={index}>
                  <div
                    className={`${styles["problem_lititle"]}`}
                    key={index}
                    onClick={() => {
                      if (expandedIndex != index) {
                        setExpandedIndex(index);
                      } else {
                        setExpandedIndex(-1);
                      }
                    }}
                  >
                    {item.title}
                    {expandedIndex === index ? (
                      <img
                        className={`${styles["listimg"]}`}
                        src={require(`../assets/image/sq.png`).default}
                      />
                    ) : (
                      <img
                        className={`${styles["listimg"]}`}
                        src={require(`../assets/image/zk.png`).default}
                      />
                    )}
                  </div>
                  {expandedIndex === index && (
                    <div
                      className={`${styles["problem_content"]}`}
                      dangerouslySetInnerHTML={{
                        __html: item.content.replace(
                          new RegExp("\n", "g"),
                          "<br/><br/>"
                        ),
                      }}
                    ></div>
                  )}
                </li>
              ))}
            </ul>
          }
        </div>
      </div>
      <UsersFooter></UsersFooter>
    </div>
  );
}
export default BuyNodes;
