import React, { Component, useEffect } from "react";
import { Space, Swiper } from "antd-mobile";
import * as bootstrap from "react-bootstrap";
import styles from "../assets/css/UserWallet.module.css";
import { Link, useHistory } from "react-router-dom";
import { UsersHeader,UsersFooter} from "../components";
import { CLOUD_API_URL } from "../constants";
import { Button, message ,Input} from 'antd';
import axios from "axios";
function Points(props) {

  const [menuShow, setMenuShow] = React.useState(false);
  const [disabled, setdisabled] = React.useState(false);
  
  const [bindinfo, setbindinfo] = React.useState({ title: "Ioannis Korkos" });
  const [Accounttitle, setbindtitle] = React.useState({ title: "Account Number" });
  const [UserInfosohw, setUserInfosohw] = React.useState(false);
  const [inputvalue, setInputvalue] = React.useState("");
  const [arrdata, setarrdata] = React.useState([]);


  function back() {
    history.push({ pathname: "/UserSignIn", state: {} });
  }

  let isphone = React.$isphone || sessionStorage.getItem("isphone")

  const handleInputChange = (event) => {
    const { value } = event.target;
    setInputvalue(value);
  };

  const history = useHistory();


  useEffect(() => {
    let address =  props.data.address
    if(address){
    axios.get(`https://api.aiworld.xyz/redeem-info/${address}`,).then(res=>{
        let data = [{ Num: '--', name: React.$i18n('Points.CurrentPoints'),href:"https://twitter.com/AIWORLD_HQ",img:require("../assets/image/sj4.png").default},
        { Num: '--', name: React.$i18n('Points.Redeemable'),href:"https://twitter.com/TTI20000101",img:require("../assets/image/sj4.png").default },
        { Num: '--', name: React.$i18n('Points.CurrentToken'),href:"https://www.youtube.com/@AIWORLD-0765/videos" ,img:require("../assets/image/ytb.png").default},
        { Num: '--', name: React.$i18n('Points.Redeemed'),href:"https://medium.com/@aiworld0765",img:require("../assets/image/sj2.png").default},];
        data[0].Num = res.data.points_hold
        data[1].Num = res.data.points_redeem_available
        data[2].Num = res.data.token_amount
        data[3].Num = res.data.points_redeem
        setarrdata(data)
    });
  }
}, [props.data.address]); // Call getChatList only once when the component mount


function Redeempoints(){
  if(!inputvalue || inputvalue<1){
    message.open({
      type: 'error',
      content: React.$i18n('Points.less1'),
      className: 'userTip-mess',
      duration:3
    });
    return
  }
   axios.post(`https://api.aiworld.xyz/redeem`,{address:props.data.address,points:Number(inputvalue)}).then(res=>{
    setdisabled(false)
    message.open({
      type: 'success',
      content: React.$i18n('Points.exchange'),
      className: 'userTip-mess',
      duration:3
    });
  }).catch(err=>{
    setdisabled(false)
    message.open({
      type: 'error',
      content:React.$i18n('Points.Failed'),
      className: 'userTip-mess',
      duration:3
    });
  });

}

  
  return (
    <div className={`${styles["container"]}`}>
      <UsersHeader></UsersHeader>
      <div style={{ display: isphone ? "none" : "block" }} className={`${styles["backUserSignIn"]}`} onClick={()=>{
            back()
           }}> {'<back'} </div> 
      <img
        className={`${styles["BindAccount"]}`}
        src={require(`../assets/image/pointitle.png`).default}
      />

       <div className={`${styles["Pointscontainer"]}`}>

      <div className={`${styles["PointsBox"]}`}>
        {arrdata.map((item,index) => (
           <div  className={`${styles["PointsBox-son"]}`} key={index}>         
                <div className={`${styles["PointsBox-top"]}`}>{item.name}</div>
                <div className={`${styles["PointsBox-Num"]}`}>{item.Num}</div>
          </div>
         ))}
      </div>

    <div className={`${styles["Pointsline"]}`}></div>

      <div className={`${styles["PointsBox_tip"]}`}>
      {React.$i18n('Points.tip1')} <br/><br/>
      {React.$i18n('Points.tip2')} <br/><br/>
      {React.$i18n('Points.tip3')} <br/><br/>
      {React.$i18n('Points.tip4')} 
      </div>


     <div className={`${styles["Pointsline"]}`}></div>

      <div
        class="PointsBox-mc"
      >
        <div className={`${styles["PointsBox-bindbox"]}`}>
          <div class="UserIntest">
            <div className={`${styles["PointsBox-title"]}`}>{React.$i18n('Points.Please')}</div>
            <div class="UserInfotext">
              <input
                className={`${styles["Accountinput"]}`}
                name="twitter"
                value={inputvalue}
                onChange={handleInputChange}
                placeholder={React.$i18n('Points.PleaseTip')}
                type="number"
                pattern="[0-9]*"
                min="1"
                max="1000000000"
              />
            </div>
            <button
              disabled={disabled}
              className={`${styles["button_1"]} flex-col align-center justify-center`}
              onClick={() => {
                setdisabled(true)
                setTimeout(() => {
                  setdisabled(false)
                }, 3000);
                Redeempoints()
              }}
            >
               {React.$i18n('Points.ConfirmBtn')}
            </button>
            {/* <div className={`${styles["PointsBox-tip"]}`}>
             If you encounter any problems during the binding process, please contact our staff in the official community.
            </div> */}
            
          </div>
        </div>
      </div>

      </div>


      <UsersFooter></UsersFooter>
    </div>
  );
}
export default Points;
