import i18n from "i18next";
import en from "./i18n/en.json";
import zh from "./i18n/zh.json";
import {
  initReactI18next
} from 'react-i18next';


let browserLang = navigator.language ? navigator.language : navigator.browserLanguage;
let  defaultlanguage = ''
if(localStorage.getItem("language")){
  defaultlanguage = localStorage.getItem("language")
}else{
  if (browserLang.toLowerCase() === 'cn' || browserLang.toLowerCase() === 'zh' || browserLang.toLowerCase() === 'zh-cn') {
    defaultlanguage = 'zh'
  } else {
    defaultlanguage = 'en'
  }
}

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: en,
    },
    zh: {
      translation: zh,
    },
  },
  //默认语言
  fallbackLng:defaultlanguage || "en",
  debug: false,
  interpolation: {
    escapeValue: false,
  },
})
// i18n.language = 'en'
// i18n.changeLanguage( i18n.language)
export default i18n;
