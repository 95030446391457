import React, { Component, useEffect } from "react";
import { Space, Swiper } from "antd-mobile";
import * as bootstrap from "react-bootstrap";
import styles from "../assets/css/UserWallet.module.css";
import { Link, useHistory } from "react-router-dom";
import { UsersHeader,UsersFooter} from "../components";
import { CLOUD_API_URL } from "../constants";

function UserFollow({ data }) {

  let isphone = React.$isphone || sessionStorage.getItem("isphone")

  const history = useHistory();
  const arrdata = [
    { id: 1, name: "AI WORLD" ,href:"https://twitter.com/AIWORLD_HQ",img:require("../assets/image/sj4.png").default},
    { id: 2, name: "AI WORLD club",href:"https://twitter.com/TTI20000101",img:require("../assets/image/sj4.png").default },
    { id: 3, name: "YouTube",href:"https://www.youtube.com/@AIWORLD-0765/videos" ,img:require("../assets/image/ytb.png").default},
    { id: 4, name: "Medium" ,href:"https://medium.com/@aiworld0765",img:require("../assets/image/sj2.png").default},
    { id: 5, name: "Korkos | AI World" ,href:"https://twitter.com/korkosAIworld",img:require("../assets/image/sj4.png").default},
    { id: 6, name: "Simon | AI World" ,href:"https://twitter.com/SimonAIworld",img:require("../assets/image/sj4.png").default},
  ];
  function back() {
    history.push({ pathname: "/UserSignIn", state: {} });
  }
  return (
    <div className={`${styles["container"]}`}>
      <UsersHeader></UsersHeader>
      <div style={{ display: isphone ? "none" : "block" }} className={`${styles["backUserSignIn"]}`} onClick={()=>{back() }}> {'<  back'} </div>    
      <div className={`${styles["socialcontactfollow"]}`}>
        {arrdata.map((item) => (
           <div  className={`${styles["sharediv"]}`} key={item.id}>         
            <a href={item.href} target="_blank" rel="noopener noreferrer">
            <img className={`${styles["shareimg"]}`} src={item.img}></img>
            {item.name}
             </a>
          </div>
         ))}
      </div>
      <a className={`${styles["gofollow"]}`} href="https://gleam.io/pvsbs/aiworld-follow-event"  target="_blank">{React.$i18n("BindAccount.AIworld")}</a>    
      <UsersFooter></UsersFooter>
    </div>
  );
}
export default UserFollow;
