/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from "react";
import { Space, Swiper } from "antd-mobile";
import styles from "./assets/index.module.css";
import "./assets/index.css";
import { Account } from "./components";
import { Link, useHistory } from "react-router-dom";
import { UsersHeader,UsersFooter} from "./components";
import { Button, message } from 'antd';
import QueueAnim from 'rc-queue-anim';
import { OverPack } from 'rc-scroll-anim';
import TweenOne from 'rc-tween-one';
import Texty from 'rc-texty';
import { useLocation } from 'react-router-dom';
import 'rc-texty/assets/index.css';
import { useTranslation } from 'react-i18next'

export default function LinkToModule(props) {
  const [menuShow, setMenuShow] = React.useState(false);
  const [MetaMaskshow, setMetaMaskshow] = React.useState(false);
  const [UserInfosohw, setUserInfosohw] = React.useState(false);
  React.$currentpage = "home"

  const [userinfo, setuserinfo] = React.useState({
    name: React.$i18n('app2.Ioannis'),
    img: require("./assets/image/Ioannis.png").default,
    desc: React.$i18n('app2.Korkos'),
  });
  // https://api.aiworld.xyz/users
  // const [checkinCnt, setCheckinCnt] = React.useState(0);
  // React.useEffect(() => {
  //   if (!props.data.address) return;
  //   fetch(
  //     `https://api.aiworld.xyz/users/${props.data.address}`
  //   )
  //     .then((response) => response.json())
  //     .then((data) => setCheckinCnt(data.cnt))
  //     .catch((error) => console.error(error));
  // }, [props.data.address]);

  const history = useHistory();
  const state = {
    isphone: false,
    MetaMaskshow: false,
  };
  function browserRedirect() {
    let screenWidth = window.innerWidth;
    var sUserAgent = navigator.userAgent.toLowerCase();
    var bIsIpad = sUserAgent.match(/ipad/i) == "ipad";
    var bIsIphoneOs = sUserAgent.match(/iphone os/i) == "iphone os";
    var bIsMidp = sUserAgent.match(/midp/i) == "midp";
    var bIsUc7 = sUserAgent.match(/rv:1.2.3.4/i) == "rv:1.2.3.4";
    var bIsUc = sUserAgent.match(/ucweb/i) == "ucweb";
    var bIsAndroid = sUserAgent.match(/android/i) == "android";
    var bIsCE = sUserAgent.match(/windows ce/i) == "windows ce";
    var bIsWM = sUserAgent.match(/windows mobile/i) == "windows mobile";
    if (
      bIsIpad ||
      bIsIphoneOs ||
      bIsMidp ||
      bIsUc7 ||
      bIsUc ||
      bIsAndroid ||
      bIsCE ||
      bIsWM || screenWidth < 500
    ) {
      //跳转移动端页面
      state.isphone = true;
      sessionStorage.setItem("isphone",true)
    } else {
      //跳转pc端页面
      state.isphone = false;
      sessionStorage.setItem("isphone",false)
    }
    React.$isphone = state.isphone
  }
 
  browserRedirect();
  function showUserInfo(index) {
    if (index == 1) {
      setuserinfo({
        ...userinfo,
        name: React.$i18n('app2.Ioannis'),
        img: require("./assets/image/Ioannis.png").default,
        desc: React.$i18n('app2.Korkos'),
      });
    } else if (index == 2) {
      setuserinfo({
        ...userinfo,
        name: "Simone De Rosa",
        img: require("./assets/image/Simone.png").default,
        desc: React.$i18n('app2.graduated') 
      });
    } else {
      setuserinfo({
        ...userinfo,
        name: "GongHui",
        img: require("./assets/image/GongHui.png").default,
        desc:React.$i18n('app2.GongHui') 
      });
    }

    setUserInfosohw(!UserInfosohw);
  }
  


  const colors = [
    {
      title:React.$i18n('app2.Authenticity'), 
      color: "#00F56E",
      icon: "./assets/img/SketchPng0219ff7c1b48602570d09bfb6721c969993c1e0471bf1f8af8ab597d5f0ca1a8.png",
      text:React.$i18n('app2.digital'),
    },
    {
      title:React.$i18n('app2.Enhanced'), 
      color: "#00E7FF",
      icon: "./assets/img/SketchPng33e7e41d9819db70247e7c914566aef47883a25c1c90bd4b1e4a581fb93b271e.png",
      text: React.$i18n('app2.capable'),
    },
    {
      title:React.$i18n('app2.Automatization'), 
      color: "#FFD40D",
      icon: "./assets/img/SketchPngfdd26823147cf023343b791f4d8116d4599a16a3372218a731e4c921c6e0e46e.png",
      text: React.$i18n('app2.blockchain'),
    },
    {
      title: React.$i18n('app2.MedicalCare'),   
      color: "#CD24FA",
      icon: "./assets/img/SketchPngd70a4f9834f87a7ddf074e1737407225e391deab7087645cdf08aa7386afd1df.png",
      text: React.$i18n('app2.app2healthcare'),
    },
    {
      title: React.$i18n('app2.LifeSciences'),  
      color: "#00F56E",
      icon: "./assets/img/SketchPng61336e9eff2bdcbc7aece30175cbb460317af7b983ba08ae6401ab9a8a1cafab.png",
      text: React.$i18n('app2.app2Blockchain'),      
    },
    {
      title: React.$i18n('app2.app2Financial'), 
      color: "#00E7FF",
      icon: "./assets/img/SketchPng3ce31021a46a9981f2446ea41ed5446c56f44baa845d38210e0abdfbc07209b0.png",
      text: React.$i18n('app2.app2industry'),       
    },
    {
      title:React.$i18n('app2.SupplyChain'),
      color: "#FFD40D",
      icon: "./assets/img/SketchPng5e0a8d0dedbecf9f9c47435b6c6fdaaba1399e2087c96fee30abe53e1b1714c8.png",
      text: React.$i18n('app2.app2changing'),    
    },
  ];

  const colors2 = [
    [
      {
        title:React.$i18n('app2.Authenticity'), 
        color: "#00F56E",
        icon: "./assets/img/SketchPng0219ff7c1b48602570d09bfb6721c969993c1e0471bf1f8af8ab597d5f0ca1a8.png",
        text:React.$i18n('app2.digital'),
      },
      {
        title:React.$i18n('app2.Enhanced'), 
        color: "#00E7FF",
        icon: "./assets/img/SketchPng33e7e41d9819db70247e7c914566aef47883a25c1c90bd4b1e4a581fb93b271e.png",
        text: React.$i18n('app2.capable'),
      },
      {
        title:React.$i18n('app2.Automatization'), 
        color: "#FFD40D",
        icon: "./assets/img/SketchPngfdd26823147cf023343b791f4d8116d4599a16a3372218a731e4c921c6e0e46e.png",
        text: React.$i18n('app2.blockchain'),
      },
    ],
    [
      {
        title: React.$i18n('app2.MedicalCare'),   
        color: "#CD24FA",
        icon: "./assets/img/SketchPngd70a4f9834f87a7ddf074e1737407225e391deab7087645cdf08aa7386afd1df.png",
        text: React.$i18n('app2.app2healthcare'),
      },
      {
        title: React.$i18n('app2.LifeSciences'),  
        color: "#00F56E",
        icon: "./assets/img/SketchPng61336e9eff2bdcbc7aece30175cbb460317af7b983ba08ae6401ab9a8a1cafab.png",
        text: React.$i18n('app2.app2Blockchain'),      
      },
      {
        title: React.$i18n('app2.app2Financial'), 
        color: "#00E7FF",
        icon: "./assets/img/SketchPng3ce31021a46a9981f2446ea41ed5446c56f44baa845d38210e0abdfbc07209b0.png",
        text: React.$i18n('app2.app2industry'),       
      },
    ],
    [
      {
        title:React.$i18n('app2.SupplyChain'),
        color: "#FFD40D",
        icon: "./assets/img/SketchPng5e0a8d0dedbecf9f9c47435b6c6fdaaba1399e2087c96fee30abe53e1b1714c8.png",
        text: React.$i18n('app2.app2changing'),    
      },
      {
        title:React.$i18n('app2.Authenticity'), 
        color: "#00F56E",
        icon: "./assets/img/SketchPng0219ff7c1b48602570d09bfb6721c969993c1e0471bf1f8af8ab597d5f0ca1a8.png",
        text:React.$i18n('app2.digital'),
      },
      {
        title:React.$i18n('app2.Enhanced'), 
        color: "#00E7FF",
        icon: "./assets/img/SketchPng33e7e41d9819db70247e7c914566aef47883a25c1c90bd4b1e4a581fb93b271e.png",
        text: React.$i18n('app2.capable'),
      },
    ],
  ];

  const items2 = colors2.map((k, index) => (
    <Swiper.Item
      key={index}
      style={{ display: "flex", justifyContent: "center" }}
    >
      {k.map((item, i) => (
        <div className="Swiperbox">
          <div
            className={`${styles["box_6"]} flex-col`}
            style={{ borderColor: `${item.color}` }}
          >
            <div className={`${styles["box_7"]} flex-row justify-between`}>
              <span
                className={`${styles["text_10"]}`}
                style={{ color: `${item.color}` }}
              >
                {item.title}
              </span>
              <img
                className={`${styles["label_3"]}`}
                src={require(`${item.icon}`).default}
              />
            </div>
            <span className={`${styles["text_11"]}`}>{item.text}</span>
          </div>
        </div>
      ))}
    </Swiper.Item>
  ));

  const items = colors.map((item, index) => (
    <Swiper.Item
      key={index}
      style={{ display: "flex", justifyContent: "center" }}
    >
      <div className={styles.content}>
        <div
          className={`${styles["box_6"]} flex-col`}
          style={{ borderColor: `${item.color}` }}
        >
          <div className={`flex-row justify-between`}>
            <span
              className={`${styles["text_10"]}`}
              style={{ color: `${item.color}` }}
            >
              {item.title}
            </span>
            <img
              className={`${styles["label_3"]}`}
              src={require(`${item.icon}`).default}
            />
          </div>
          <span className={`${styles["text_11"]}`}>{item.text}</span>
        </div>
      </div>
    </Swiper.Item>
  ));

  React.useEffect(() => {
    
    setTimeout(() => {
      const element = document.querySelector('.fade-in-outheader');
      element.classList.add('visible');
    }, 1000);

    function handleScroll() {
      const scrollPosition = window.pageYOffset;
    //  console.log(scrollPosition);
       if(scrollPosition>10){
          const text = document.querySelector('.text-fade-in');
          text.classList.add('visible');
          setTimeout(() => {
            const btn = document.querySelector('.btn-fade-in');
            btn.classList.add('visible');
          }, 500);
      }
   
      if(state.isphone == false){
       if(scrollPosition>4500){
           const element = document.querySelector('.fade-in-out');
           element.classList.add('visible');
       }
       if(scrollPosition>4700){
        const element = document.querySelector('.fade-in-out2');
        element.classList.add('visible');
       }
       if(scrollPosition>4800){
        const element = document.querySelector('.fade-in-out3');
        element.classList.add('visible');

       }
       if(scrollPosition>5000){
        const element = document.querySelector('.fade-in-out4');
        element.classList.add('visible');
       }
      }else{
        if(scrollPosition>3900){
              const element = document.querySelector('.fade-in-out');
              element.classList.add('visible');
          }
          if(scrollPosition>4000){
            const element = document.querySelector('.fade-in-out2');
            element.classList.add('visible');
          }
          if(scrollPosition>4200){
            const element = document.querySelector('.fade-in-out3');
            element.classList.add('visible');

          }
          if(scrollPosition>4300){
            const element = document.querySelector('.fade-in-out4');
            element.classList.add('visible');
          }


      }


    }
  
    window.addEventListener("scroll", handleScroll);
  
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);


  function handleScroll() {
    const scrollPosition = window.pageYOffset;
    const element = document.querySelector('.fade-in-out');
    if (element) {
      const elementTop = element.getBoundingClientRect().top + scrollPosition;
      const elementBottom = elementTop + element.offsetHeight;
      if (scrollPosition + window.innerHeight > elementTop && scrollPosition < elementBottom) {
        element.classList.add('visible');
      } else {
        element.classList.remove('visible');
      }
    }
  }




  return (
    <div className={"container-fluid"}>
      <UsersHeader></UsersHeader>
      <div
        className={`${styles["box_1"]} flex-col align-center`}
        style={{ display: menuShow ? "none" : "flex" }}
      >
        <div className={`fade-in-outheader`} style={{ display: state.isphone ? "none" : "block" }}>    
         <div className={`${styles["AIWORLD"]}`}>AIWORLD</div>
          <img
            className={`${styles["ai"]}`}
            src={require("./assets/image/pcheader_ai.png").default}
          />       
        </div>
        <span className={`${styles["text_1"]} text-fade-in`}>         
         {React.$i18n('app2.intersect')}
        </span>
        <button
          className={`${styles["button_1"]} flex-col align-center justify-center btn-fade-in`}
          onClick={() => {
            message.open({
              type: 'warning',
              content:React.$i18n('AiwordGlobalL.Comingsoon'),
              className: 'userTip-mess',
              duration:3
            });
          }}
        >
          {React.$i18n('app2.Surprise')}
        </button>
        <img
          className={`${styles["label_2"]}`}
          src={
            require("./assets/img/SketchPng0250fa2b069c577841024f977cb87a13e28c6e5986545df6dabd6c37290ef9b7.png")
              .default
          }
        />
      </div> 

      <div className={`${styles["box_2"]} flex-col align-center`}>
        <div className={`${styles["section_1"]} flex-row`}>
          <img
            className={`${styles["thumbnail_1"]}`}
            src={
              require("./assets/image/bt.png")
                .default
            }
          />     
        </div>
        <div className={`${styles["section_3"]} flex-col`}>
          <div className={`${styles["text-wrapper_1"]} flex-col`}>
            {React.$i18n('app2.Blockchain')}
          </div>
        </div>
      </div>
      <div className={`${styles["text-wrapper_2"]} flex-col align-center`}>
        <span className={`${styles["text_5"]}`}>
          {React.$i18n('app2.technology')}
        </span>
      </div>
      <div className={`${styles["box_5"]} flex-col align-center`}>
        <div className={`${styles["group_1"]} flex-row`}>
          <div className={`${styles["text-wrapper_3"]} flex-col align-center`}></div>
        </div>
        <div className={`${styles["text-wrapper_4"]} flex-row`}>
          <span className={`${styles["text_7"]}`}>       
            {React.$i18n('app2.Combination')}
          </span>
        </div>
      </div>
      <div
        className={`page-swiper`}
        style={{
          width: "100%",
        }}
      >
        <Space direction="vertical"  autoplay autoplayInterval={2000} block>
          <Swiper
            loop
            autoplay
            autoplayInterval="3000"
            style={{
              "--track-padding": " 0 0 16px",
            }}
            indicatorProps={{
              color: "white",
            }}
          >
            {state.isphone ? items : items2}
          </Swiper>
        </Space>
      </div>

      <div className={`${styles["box_8"]} flex-col align-center`}>
        <button
          disabled={!props.data || !props.data.address}
          className={`${styles["button_1"]} flex-col align-center justify-center`}
          onClick={() => {
            message.open({
              type: 'warning',
              content:React.$i18n('AiwordGlobalL.Comingsoon'),
              className: 'userTip-mess',
              duration:3
            });
          }}
        >
          {React.$i18n('app2.Surprise')}
        </button>
        <img
          className={`${styles["image_6"]}`}
          src={
            require("./assets/img/SketchPnga1ee1a72e431c7b3f51d3b022406b25b96de142923c3eedbfe985c30d3e8a2cc.png")
              .default
          }
        />
        <div className={`${styles["text-wrapper_7"]} flex-col`}></div>
      </div>

      <div className={`${styles["text-wrapper_8"]} flex-col`}>
        <span className={`${styles["paragraph_1"]}`}>
          <div style={{marginBottom:"20px"}}> {React.$i18n('app2.totalnumber')}</div> 
          <div>{React.$i18n('app2.totalnumber2')}</div>         
        </span>
      </div>

      <div className={`${styles["box_9"]} flex-col`}>
        <span className={`${styles["text_14"]}`}>    
           {React.$i18n('app2.tokens')}
        </span>
        <div className={`${styles["image-text"]} flex-row`}>
          <div className={`${styles["boxdd"]} flex-col`} />
          <span className={`${styles["text-group"]}`}>
          {React.$i18n('app2.held')}         
          </span>
        </div>
        <div className={`${styles["image-text"]} flex-row`}>
          <div className={`${styles["boxdd"]} flex-col`} />
          <span className={`${styles["text-group"]}`}>
          {React.$i18n('app2.generated')}          
          </span>
        </div>
        <div className={`${styles["image-text"]} flex-row`}>
          <div className={`${styles["boxdd"]} flex-col`} />
          <span className={`${styles["text-group"]}`}>
          {React.$i18n('app2.allocated')}        
          </span>
        </div>
        <div className={`${styles["image-text"]} flex-row`}>
          <div className={`${styles["boxdd"]} flex-col`} />
          <span className={`${styles["text-group"]}`}>
          {React.$i18n('app2.distributed')}          
          </span>
        </div>
       
        <div className={`${styles["image-text"]} flex-rown`}>
          <div className={`${styles["boxdd"]} flex-col`} />
          <span className={`${styles["text-group"]}`}>
          {React.$i18n('app2.team')}         
          </span>
        </div>
      </div>
      <div className={`${styles["text-wrapper_9"]} flex-col`}>
        <span className={`${styles["text_14"]}`}>{React.$i18n('app2.Computing')}</span>
        <span className={`${styles["text_16"]}`}>
        {React.$i18n('app2.maximum')}    
        </span>
        <span className={`${styles["text_17"]}`}>
        {React.$i18n('app2.computational')} 
        </span>
      </div>
      
      <div className={`${styles["box_13"]} flex-col`}>
        <div className={`${styles["text-group_5"]} flex-col justify-between`}>
          <span className={`${styles["text_20"]}`}>
          {React.$i18n('app2.computing')}          
          </span>
          <span className={`${styles["text_21"]}`}>
          {React.$i18n('app2.increases')}           
          </span>
        </div>
        <div className={`${styles["section_5"]} flex-row`}>
          <span className={`${styles["text_22"]}`}>{React.$i18n('app2.Nodes')} 1-200</span>
          <img
            className={`${styles["thumbnail_3"]}`}
            src={
              require("./assets/img/SketchPng24aa65adb7f45d4b60245dc7660aa14efa111295f2fe161c0a535d554f36a36a.png")
                .default
            }
          />
          <span className={`${styles["text_23"]}`}>
            1600u {React.$i18n('app2.pernod')}
          </span>
        </div>
        <div className={`${styles["section_6"]} flex-row`}>
          <span className={`${styles["text_22"]}`}>{React.$i18n('app2.Nodes')} 	201-400</span>
          <img
            className={`${styles["thumbnail_3"]}`}
            src={
              require("./assets/img/SketchPng650d1cfc33801305db4675b535cb148d3f6c42cc544c64ef9074e6d389091b8c.png")
                .default
            }
          />
          <span className={`${styles["text_23"]}`}>
            1616u {React.$i18n('app2.pernod')}
          </span>
        </div>
        <div className={`${styles["section_5"]} flex-row`}>
          <span className={`${styles["text_22"]}`}>{React.$i18n('app2.Nodes')} 401-600</span>
          <img
            className={`${styles["thumbnail_3"]}`}
            src={
              require("./assets/img/SketchPng24aa65adb7f45d4b60245dc7660aa14efa111295f2fe161c0a535d554f36a36a.png")
                .default
            }
          />
          <span className={`${styles["text_23"]}`}>
            1632u {React.$i18n('app2.pernod')}
          </span>
        </div>
        <div className={`${styles["section_6"]} flex-row`}>
          <span className={`${styles["text_22"]}`}>{React.$i18n('app2.Nodes')} 601-800</span>
          <img
            className={`${styles["thumbnail_3"]}`}
            src={
              require("./assets/img/SketchPng650d1cfc33801305db4675b535cb148d3f6c42cc544c64ef9074e6d389091b8c.png")
                .default
            }
          />
          <span className={`${styles["text_23"]}`}>
           1648u {React.$i18n('app2.pernod')}
          </span>
        </div>
        <div className={`${styles["section_5"]} flex-row`}>
          <span className={`${styles["text_22"]}`}>
            {React.$i18n('app2.Nodes')}  801-1000
          </span>
          <img
            className={`${styles["thumbnail_3"]}`}
            src={
              require("./assets/img/SketchPng24aa65adb7f45d4b60245dc7660aa14efa111295f2fe161c0a535d554f36a36a.png")
                .default
            }
          />
          <span className={`${styles["text_23"]}`}>
           1664u {React.$i18n('app2.pernod')}
          </span>
        </div>
        <div className={`${styles["section_6"]} flex-row`}>
          <span className={`${styles["text_22"]}`}>{React.$i18n('app2.Nodes')}	n-(n+200)</span>
          <img
            className={`${styles["thumbnail_3"]}`}
            src={
              require("./assets/img/SketchPng650d1cfc33801305db4675b535cb148d3f6c42cc544c64ef9074e6d389091b8c.png")
                .default
            }
          />
          <span className={`${styles["text_23"]}`}>
          (1600*(1.01)^n)u {React.$i18n('app2.pernod')}
          </span>
        </div>
        <div className={`${styles["section_5"]} flex-row`}>
          <span className={`${styles["text_22"]}`}>
            {React.$i18n('app2.Nodes')} 19201-19400
          </span>
          <img
            className={`${styles["thumbnail_3"]}`}
            src={
              require("./assets/img/SketchPng24aa65adb7f45d4b60245dc7660aa14efa111295f2fe161c0a535d554f36a36a.png")
                .default
            }
          />
          <span className={`${styles["text_23"]}`}>
          11382u {React.$i18n('app2.pernod')}
          </span>
        </div>
        <div className={`${styles["section_6"]} flex-row`}>
          <span className={`${styles["text_22"]}`}>{React.$i18n('app2.Nodes')} 19401-19600</span>
          <img
            className={`${styles["thumbnail_3"]}`}
            src={
              require("./assets/img/SketchPng650d1cfc33801305db4675b535cb148d3f6c42cc544c64ef9074e6d389091b8c.png")
                .default
            }
          />
          <span className={`${styles["text_23"]}`}>
          11487u {React.$i18n('app2.pernod')}
          </span>
        </div>
        <div className={`${styles["section_5"]} flex-row`}>
          <span className={`${styles["text_22"]}`}>{React.$i18n('app2.Nodes')} 19601-19800</span>
          <img
            className={`${styles["thumbnail_3"]}`}
            src={
              require("./assets/img/SketchPng24aa65adb7f45d4b60245dc7660aa14efa111295f2fe161c0a535d554f36a36a.png")
                .default
            }
          />
          <span className={`${styles["text_23"]}`}>
           11595u {React.$i18n('app2.pernod')}
          </span>
        </div>
        <div className={`${styles["section_6"]} flex-row`}>
          <span className={`${styles["text_22"]}`}>{React.$i18n('app2.Nodes')} 19801-20000</span>
          <img
            className={`${styles["thumbnail_3"]}`}
            src={
              require("./assets/img/SketchPng650d1cfc33801305db4675b535cb148d3f6c42cc544c64ef9074e6d389091b8c.png")
                .default
            }
          />
          <span className={`${styles["text_23"]}`}>
          11693u {React.$i18n('app2.pernod')}
          </span>
        </div>

        
        <span className={`${styles["text_42"]}`}>      
        {React.$i18n('app2.Owning')}
        </span>
        <button
          className={`${styles["button_1"]} flex-col align-center justify-center`}
          onClick={() => {
            React.$currentpage = "NODES"
            history.push({ pathname: "/Nodes", state: {} });
          }}
        >
          {React.$i18n('app2.Surprise')}
        </button>
      </div>

      <div className={`${styles["box_20"]} flex-col align-center`}>
        <img
          className={`${styles["image_10"]}`}
          src={
            require("./assets/img/SketchPnga1ee1a72e431c7b3f51d3b022406b25b96de142923c3eedbfe985c30d3e8a2cc.png")
              .default
          }
        />
        <div className={`${styles["text-wrapper_16"]} flex-col`}>
          <span className={`${styles["paragraph_6"]}`}>          
            {React.$i18n('app2.feel')}
            <br />
            {React.$i18n('app2.world')}           
          </span>
        </div>
        <div
          className={`${styles["block_8"]} flex-col`}
          onClick={() => {
            history.push({ pathname: "/Chat", state: {} });
          }}
        >
          <textarea
            type="text"
            className={`${styles["text_61"]}`}
            placeholder={React.$i18n('app2.Writepaste')}
          ></textarea>
          <div className={`${styles["box_21"]} flex-row justify-between`}>
            <button
              className={`${styles["button_5"]} flex-col align-center justify-center`}
              style={{ marginLeft: "20px" }}
              onClick={() => {}}
            >
              <img
                className={`${styles["thumbnail_13"]}`}
                src={
                  require("./assets/img/SketchPng30dbed526d23c664a9acb8c944a47745e4f49675195d6ea2d508b52f61ccb7b7.png")
                    .default
                }
              />
              <span className={`${styles["text_62"]}`}>+</span>
            </button>
            <div
              className={`${styles["group_3"]} flex-row`}
              style={{ marginLeft: "20px" }}
            >
              <span className={`${styles["text_63"]}`}>{React.$i18n('app2.Button')}</span>
              <img
                className={`${styles["image_11"]}`}
                src={
                  require("./assets/img/SketchPng1a878cbac5632a679cfbb37651007fba7535448a79537d3833df22f17bc62666.png")
                    .default
                }
              />
            </div>
          </div>
        </div>
        <img
          className={`${styles["image_12"]}`}
          src={
            require("./assets/img/SketchPnga1ee1a72e431c7b3f51d3b022406b25b96de142923c3eedbfe985c30d3e8a2cc.png")
              .default
          }
        />
        <div className={`flex-col`}>
          <img
            className={`${styles["coreteam"]}`}
            src={require("./assets/image/coreteam.png").default}
          />
        </div>
        <div className={`${styles["block_2999-0"]}`}>
          <div className={`${styles["block_10"]}`}>
            <img
              className={`${styles["single-avatar"]}`}
              src={require("./assets/image/Ioannis.png").default}
            />
            <div
              className={`${styles["single-more"]}`}
              onClick={() => {
                showUserInfo(1);
              }}
            >
               {React.$i18n('app2.More')}<span style={{ fontSize: "12px" }}>{">"}</span>
            </div>
            <div className={`${styles["text-group_11"]}`}>
              <div className={`${styles["text_65"]}`}>{React.$i18n('app2.Ioannis')}</div>
              <div className={`${styles["Introduction"]}`}>
              {React.$i18n('app2.Korkos')}               
              </div>
            </div>
          </div>
          <div className={`${styles["block_10"]}`}>
            <img
              className={`${styles["single-avatar"]}`}
              src={require("./assets/image/Simone.png").default}
            />
            <div
              className={`${styles["single-more"]}`}
              onClick={() => {
                showUserInfo(2);
              }}
              style={{ color: "#00E7FF" }}
            >
              {React.$i18n('app2.More')} <span style={{ fontSize: "12px" }}>{">"}</span>
            </div>
            <div className={`${styles["text-group_11"]}`}>
              <div
                className={`${styles["text_65"]}`}
                style={{ color: "#00E7FF" }}
              >
                Simone De Rosa
              </div>
              <div className={`${styles["Introduction"]}`}>
                 {React.$i18n('app2.graduated')} 
              </div>
            </div>
          </div>
          <div className={`${styles["block_10"]}`}>
            <img
              className={`${styles["single-avatar"]}`}
              src={require("./assets/image/GongHui.png").default}
            />
            <div
              className={`${styles["single-more"]}`}
              onClick={() => {
                showUserInfo(3);
              }}
              style={{ color: "#FFD40D" }}
            >
              {React.$i18n('app2.More')} <span style={{ fontSize: "12px" }}>{">"}</span>
            </div>
            <div className={`${styles["text-group_11"]}`}>
              <div
                className={`${styles["text_65"]}`}
                style={{ color: "#FFD40D" }}
              >
                GongHui
              </div>
              <div className={`${styles["Introduction"]}`}>
                {React.$i18n('app2.GongHui')} 
              </div>
            </div>
          </div>

          <div
            class="UserInformation"
            style={{ display: UserInfosohw ? "block" : "none" }}
          >
            <div class="UserInformationbox">
              <img
                className={`${styles["single-avatar"]}`}
                src={userinfo.img}
              />
              <img
                class="closeInformation"
                onClick={() => setUserInfosohw(!UserInfosohw)}
                src={
                  require("./assets/img/SketchPnge6b0562b3e1068bfcdd385f9e0568600ad5816771f1ba1ae906f13cbbc55c45b.png")
                    .default
                }
              />
              <div class="UserIntest">
                <div className={`${styles["text_65"]}`}>{userinfo.name}</div>
                <div class="UserInfotext">{userinfo.desc}</div>
              </div>
            </div>
          </div>
        </div>

        <img
          className={`${styles["image_13"]}`}
          src={
            require("./assets/img/SketchPnga1ee1a72e431c7b3f51d3b022406b25b96de142923c3eedbfe985c30d3e8a2cc.png")
              .default
          }
        />
      </div>





      <div className={`${styles["box_15"]} flex-col`} id="footer">
        <div className={`${styles["footer"]} flex-col align-center`}>
        <img
          className={`${styles["footerimg"]}`}
          src={
            require("./assets/image/footerimg.png")
              .default
          }
        />
          <div className={`${styles["block_5"]}  flex-row justify-between`}>
            <div className={`${styles["section_15"]} flex-col`} />
            <div className={`${styles["section_18"]}  flex-row fade-in-out`}>
              <div
                className={`${styles["text-group_9"]} flex-col justify-between`}
              >
                <span className={`${styles["text_46"]}`}>Q2 2023</span>
                <span className={`${styles["paragraph_4"]}`}>
                  {React.$i18n('app2.Launch')} <br />                
                  {React.$i18n('app2.chain')} <br />
                  {React.$i18n('app2.GPT')} <br />
                  {React.$i18n('app2.MID')} <br />
                  {React.$i18n('app2.Deploy')}
                </span>
              </div>
            </div>
          </div>
          <div className={`${styles["block_5"]} flex-row justify-between`}>
            <div className={`${styles["section_16"]} flex-col`} />
            <div className={`${styles["section_18"]} rightdiv flex-row fade-in-out2`}>
              <div
                className={`${styles["text-group_9"]} flex-col justify-between`}
              >
                <span className={`${styles["text_47"]}`}>Q3 2023</span>
                <span className={`${styles["paragraph_4"]}`}>
                  {React.$i18n('app2.Q3tip1')} <br />                
                  {React.$i18n('app2.Q3tip2')} <br />
                  {React.$i18n('app2.Q3tip3')} <br />
                  {React.$i18n('app2.Q3tip4')}
                </span>
              </div>
            </div>
          </div>
          <div className={`${styles["block_5"]} flex-row justify-between`}>
            <div className={`${styles["section_17"]} flex-col`} />
            <div className={`${styles["section_18"]} flex-row fade-in-out3`}>
              <div
                className={`${styles["text-group_9"]} flex-col justify-between`}
              >
                <span className={`${styles["text_48"]}`}>Q4 2023</span>
                <span className={`${styles["paragraph_4"]}`}>
                  {React.$i18n('app2.Q4tip1')} <br />                
                  {React.$i18n('app2.Q4tip2')} <br />
                  {React.$i18n('app2.Q4tip3')} <br />
                  {React.$i18n('app2.Q4tip4')} <br />
                  {React.$i18n('app2.Q4tip5')} 
                </span>
              </div>
            </div>
          </div>
          <div className={`${styles["block_5"]} flex-row  justify-between`}>
            <div className={`${styles["box_18"]} flex-col`} />
            <div className={`${styles["box_19"]} rightdiv flex-row fade-in-out4`}>
              <div
                className={`${styles["text-group_10"]} flex-col justify-between`}
              >
                <span className={`${styles["text_50"]}`}>Q1 2024</span>
                <span className={`${styles["paragraph_5"]}`}>
                {React.$i18n('app2.Q1tip1')} <br />                
                {React.$i18n('app2.Q1tip2')} <br />
                </span>
              </div>
            </div>
          </div>
          <button
            className={`${styles["button_4"]} flex-col align-center justify-center`}
            onClick={() => {
              message.open({
                type: 'warning',
                content: React.$i18n('AiwordGlobalL.Comingsoon'),
                className: 'userTip-mess',
                duration:3
              });
            }}
          >
            {React.$i18n('app2.Surprise')}
          </button>
        </div>
  </div>
       <UsersFooter></UsersFooter>
      
    </div>   
  );
}
