import React, { Component, useEffect } from "react";
import { Space, Swiper } from "antd-mobile";
import * as bootstrap from "react-bootstrap";
import styles from "../assets/css/UserWallet.module.css";
import { Link, useHistory } from "react-router-dom";
import { UsersHeader,UsersFooter} from "../components";
import { Button, message } from 'antd';

function Nodes(props ) {
    const state = {
        isphone: false,
        MetaMaskshow: false,
      };
      function browserRedirect() {
        var sUserAgent = navigator.userAgent.toLowerCase();
        var bIsIpad = sUserAgent.match(/ipad/i) == "ipad";
        var bIsIphoneOs = sUserAgent.match(/iphone os/i) == "iphone os";
        var bIsMidp = sUserAgent.match(/midp/i) == "midp";
        var bIsUc7 = sUserAgent.match(/rv:1.2.3.4/i) == "rv:1.2.3.4";
        var bIsUc = sUserAgent.match(/ucweb/i) == "ucweb";
        var bIsAndroid = sUserAgent.match(/android/i) == "android";
        var bIsCE = sUserAgent.match(/windows ce/i) == "windows ce";
        var bIsWM = sUserAgent.match(/windows mobile/i) == "windows mobile";
        if (
          bIsIpad ||
          bIsIphoneOs ||
          bIsMidp ||
          bIsUc7 ||
          bIsUc ||
          bIsAndroid ||
          bIsCE ||
          bIsWM
        ) {
          //跳转移动端页面
          state.isphone = true;
        } else {
          //跳转pc端页面
          state.isphone = false;
        }
      }
      browserRedirect();

  const history = useHistory();
  function back() {
    history.push({ pathname: "/UserSignIn", state: {} });
  }
  const [expandedIndex, setExpandedIndex] = React.useState(-1);

  const items = [
    { title: React.$i18n('Nodes.itemstitle1'),content:React.$i18n('Nodes.itemscontent1')},
    { title: React.$i18n('Nodes.itemstitle2'),content:React.$i18n('Nodes.itemscontent2')},
    { title: React.$i18n('Nodes.itemstitle3'),content:React.$i18n('Nodes.itemscontent3')},
    { title: React.$i18n('Nodes.itemstitle4'),content:React.$i18n('Nodes.itemscontent4')}, 
    { title: React.$i18n('Nodes.itemstitle5'),content:React.$i18n('Nodes.itemscontent5')},
    // { title: React.$i18n('Nodes.itemstitle6'),content:React.$i18n('Nodes.itemscontent6')},   
  ];
  function copyLink(){
    const createInput = document.createElement('input');
    createInput.value = `https://aiworld.xyz/?share=${props.data.address}`
    document.body.appendChild(createInput);
    createInput.select();
    document.execCommand('Copy'); // document执行复制操作
    createInput.remove();
    message.open({
      type: 'success',
      content: 'Successfully copied invitation link',
      className: 'userTip-mess',
      duration:3
    });
  }

  return (
    <div className={`${styles["container"]}`}>
      <UsersHeader></UsersHeader>

 <img
style={{ display: state.isphone ? "none" : "block" }}
className={`${styles["nodeheaderbc"]}`}
src={require(`../assets/image/nodeheaderbc.png`).default}
/>  
<div className={`${styles["nodeheader"]}`}>

  <div  className={`${styles["mynodebody"]}`}>
      <img
        style={{ display: state.isphone ? "block" : "none" }}
        className={`${styles["nodeheader"]}`}
        src={require(`../assets/image/nodeheader.png`).default}
      />  
      <div className={`${styles["node_network"]}`}>
       {React.$i18n('Nodes.Decentralized')} 
       <button
              className={`${styles["UserInvBtn"]}`}
              style={{margin: 'auto',marginTop:'30px'}}
              onClick={() => { 
                history.push({ pathname: "/BuyNodes", state: {} });
              }}
            >
            {React.$i18n('Nodes.PURCHASE')} 
        </button>
    </div>
 </div>
       <img
          className={`${styles["node2header"]}`}
          src={require(`../assets/image/node2header.png`).default}
        /> 
    <div className={`${styles["nodedaily"]}`}>
      <div className={`${styles["nodedaily_list1"]}`}>
            <div className={`${styles["nodedaily_title"]}`}  style={{ color:"#00F56E"}}>{React.$i18n('Nodes.DAILY')}</div>
            <div className={`${styles["nodedaily_text"]}`}>
             {React.$i18n('Nodes.computational')}
            </div>
      </div>
      <div className={`${styles["nodedaily_list2"]}`}>
      <div className={`${styles["nodedaily_title"]}`}  style={{ color:"#FFD40D"}}>{React.$i18n('Nodes.VOTING')}</div>
            <div className={`${styles["nodedaily_text"]}`}>
            {React.$i18n('Nodes.nodewill')}
            </div>
      </div>
      <div className={`${styles["nodedaily_list3"]}`}>
      <div className={`${styles["nodedaily_title"]}`}  style={{ color:"#CD24FA"}}>{React.$i18n('Nodes.EXCLUSIVE')}</div>
            <div className={`${styles["nodedaily_text"]}`}>
              {React.$i18n('Nodes.owners')}
            </div>
      </div>
    
    </div>

    <div className={`${styles["nodeline"]}`}></div>
       <img
          className={`${styles["node2header"]}`}
          src={require(`../assets/image/newpaice.png`).default}
        /> 

    <div className={`${styles["box_13"]} flex-col`}>
        <div className={`${styles["text-group_5"]} flex-col justify-between`}>
          <span className={`${styles["text_20"]}`}>
          {React.$i18n('Nodes.priced')}        
          </span>
          <span className={`${styles["text_21"]}`}>
          {React.$i18n('Nodes.increases')}          
          </span>
        </div>

        <div className={`${styles["section_5"]} flex-row`}>
          <span className={`${styles["text_22"]}`}>{React.$i18n('app2.Nodes')} 1-200</span>
          <img
            className={`${styles["thumbnail_3"]}`}
            src={
              require("../assets/img/SketchPng24aa65adb7f45d4b60245dc7660aa14efa111295f2fe161c0a535d554f36a36a.png")
                .default
            }
          />
          <span className={`${styles["text_23"]}`}>
            1600u {React.$i18n('app2.pernod')}
          </span>
        </div>
        <div className={`${styles["section_6"]} flex-row`}>
          <span className={`${styles["text_22"]}`}>{React.$i18n('app2.Nodes')} 	201-400</span>
          <img
            className={`${styles["thumbnail_3"]}`}
            src={
              require("../assets/img/SketchPng650d1cfc33801305db4675b535cb148d3f6c42cc544c64ef9074e6d389091b8c.png")
                .default
            }
          />
          <span className={`${styles["text_23"]}`}>
            1616u {React.$i18n('app2.pernod')}
          </span>
        </div>
        <div className={`${styles["section_5"]} flex-row`}>
          <span className={`${styles["text_22"]}`}>{React.$i18n('app2.Nodes')} 401-600</span>
          <img
            className={`${styles["thumbnail_3"]}`}
            src={
              require("../assets/img/SketchPng24aa65adb7f45d4b60245dc7660aa14efa111295f2fe161c0a535d554f36a36a.png")
                .default
            }
          />
          <span className={`${styles["text_23"]}`}>
            1632u {React.$i18n('app2.pernod')}
          </span>
        </div>
        <div className={`${styles["section_6"]} flex-row`}>
          <span className={`${styles["text_22"]}`}>{React.$i18n('app2.Nodes')} 601-800</span>
          <img
            className={`${styles["thumbnail_3"]}`}
            src={
              require("../assets/img/SketchPng650d1cfc33801305db4675b535cb148d3f6c42cc544c64ef9074e6d389091b8c.png")
                .default
            }
          />
          <span className={`${styles["text_23"]}`}>
           1648u {React.$i18n('app2.pernod')}
          </span>
        </div>
        <div className={`${styles["section_5"]} flex-row`}>
          <span className={`${styles["text_22"]}`}>
            {React.$i18n('app2.Nodes')}  801-1000
          </span>
          <img
            className={`${styles["thumbnail_3"]}`}
            src={
              require("../assets/img/SketchPng24aa65adb7f45d4b60245dc7660aa14efa111295f2fe161c0a535d554f36a36a.png")
                .default
            }
          />
          <span className={`${styles["text_23"]}`}>
           1664u {React.$i18n('app2.pernod')}
          </span>
        </div>
        <div className={`${styles["section_6"]} flex-row`}>
          <span className={`${styles["text_22"]}`}>{React.$i18n('app2.Nodes')}	n-(n+200)</span>
          <img
            className={`${styles["thumbnail_3"]}`}
            src={
              require("../assets/img/SketchPng650d1cfc33801305db4675b535cb148d3f6c42cc544c64ef9074e6d389091b8c.png")
                .default
            }
          />
          <span className={`${styles["text_23"]}`}>
          (1600*(1.01)^n)u {React.$i18n('app2.pernod')}
          </span>
        </div>
        <div className={`${styles["section_5"]} flex-row`}>
          <span className={`${styles["text_22"]}`}>
            {React.$i18n('app2.Nodes')} 19201-19400
          </span>
          <img
            className={`${styles["thumbnail_3"]}`}
            src={
              require("../assets/img/SketchPng24aa65adb7f45d4b60245dc7660aa14efa111295f2fe161c0a535d554f36a36a.png")
                .default
            }
          />
          <span className={`${styles["text_23"]}`}>
          11382u {React.$i18n('app2.pernod')}
          </span>
        </div>
        <div className={`${styles["section_6"]} flex-row`}>
          <span className={`${styles["text_22"]}`}>{React.$i18n('app2.Nodes')} 19401-19600</span>
          <img
            className={`${styles["thumbnail_3"]}`}
            src={
              require("../assets/img/SketchPng650d1cfc33801305db4675b535cb148d3f6c42cc544c64ef9074e6d389091b8c.png")
                .default
            }
          />
          <span className={`${styles["text_23"]}`}>
          11487u {React.$i18n('app2.pernod')}
          </span>
        </div>
        <div className={`${styles["section_5"]} flex-row`}>
          <span className={`${styles["text_22"]}`}>{React.$i18n('app2.Nodes')} 19601-19800</span>
          <img
            className={`${styles["thumbnail_3"]}`}
            src={
              require("../assets/img/SketchPng24aa65adb7f45d4b60245dc7660aa14efa111295f2fe161c0a535d554f36a36a.png")
                .default
            }
          />
          <span className={`${styles["text_23"]}`}>
           11595u {React.$i18n('app2.pernod')}
          </span>
        </div>
        <div className={`${styles["section_6"]} flex-row`}>
          <span className={`${styles["text_22"]}`}>{React.$i18n('app2.Nodes')} 19801-20000</span>
          <img
            className={`${styles["thumbnail_3"]}`}
            src={
              require("../assets/img/SketchPng650d1cfc33801305db4675b535cb148d3f6c42cc544c64ef9074e6d389091b8c.png")
                .default
            }
          />
          <span className={`${styles["text_23"]}`}>
          11693u {React.$i18n('app2.pernod')}
          </span>
        </div>

        <span className={`${styles["text_42"]}`}>
         {React.$i18n('app2.Owning')}
        </span>
        <button
          className={`${styles["button_1"]} flex-col align-center justify-center`}
          onClick={() => {
            history.push({ pathname: "/BuyNodes", state: {} });
          }}
        >
         {React.$i18n('Nodes.APPLYNODE')} 
        </button>
      </div>

     <div className={`${styles["Nowinvite"]}`}>
         <div   className={`${styles["InviteFriends"]}`}>
         <img
          className={`${styles["InviteFriend"]}`}
          src={require(`../assets/image/InviteFriend.png`).default}
        /> 
         </div>
         <div className={`${styles["Nowpoints"]}`}>
                <div className={`${styles["Nowpoints_top"]}`}>{React.$i18n('Nodes.friends')} <br/> {React.$i18n('Nodes.bonuspoints')}</div>    
                <button
              className={`${styles["UserInvBtn"]}`}
              onClick={() => {
                copyLink()
              }}
            >
          {React.$i18n('Nodes.InviteFriends')} 
        </button>
         </div>
     </div>

  <img
      className={`${styles["node2header"]}`}
      src={require(`../assets/image/tnodeitlepc.png`).default}
    />

<div className={`${styles["problemList"]}`}>
      {
        <ul className={`${styles["problem_ul"]}`}>
        {items.map((item, index) => (
          <li  className={`${styles["problem_li"]}`} key={index}>
            <div className={`${styles["problem_lititle"]}`} key={index} onClick={() =>{
              if(expandedIndex!=index){
                setExpandedIndex(index)
              }else{
                setExpandedIndex(-1)
              }
            } }>
              {item.title}

              {expandedIndex === index?<img
                className={`${styles["listimg"]}`}
                src={require(`../assets/image/sq.png`).default}
              />:<img
              className={`${styles["listimg"]}`}
              src={require(`../assets/image/zk.png`).default}
            /> }

            </div>
            {expandedIndex === index && (
              // <div className={`${styles["problem_content"]}`}>
              //   {item.content}               
              // </div>
              <div
              className={`${styles["problem_content"]}`}
              dangerouslySetInnerHTML={{
                __html: item.content.replace(
                  new RegExp("\n", "g"),
                  "<br/><br/>"
                ),
              }}
            ></div>
              
            )}
          </li>
        ))}
      </ul>
  }

</div>






  </div>
      <UsersFooter></UsersFooter>
    </div>
  );
}
export default Nodes;
