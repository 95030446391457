import React, { useCallback, useEffect,Component} from "react";
import { Space, Swiper } from "antd-mobile";
import styles from "../assets/css/UserWallet.module.css";
import { Link,useHistory} from "react-router-dom";
import {UsersHeader,UsersFooter} from "../components";
import Calendar from "react-calendar";
import axios from "axios";
import { Button, message } from 'antd';

function UserCalendars(props) {
  const [menuShow, setMenuShow] = React.useState(false);
  const [checkInDate, setcheckInDate] = React.useState([]);
  const [address, setaddress] = React.useState(props.data.address);
  const history = useHistory()
  function back(){
    history.push({pathname: '/UserSignIn', state: { }})
  }
  let  [checkInstatus, setcheckInstatus]  = React.useState(0); 
  let isphone = React.$isphone || sessionStorage.getItem("isphone")


  const [date, setDate] = React.useState(new Date());
  const [signedIn, setSignedIn] = React.useState(false);
  const [className, setclassName] = React.useState("");


  function handleDateChange(date) {
    setDate(date);
  }
  const [checkinCnt, setCheckinCnt] = React.useState(0);
  const [checkinsHistroy, setCheckinsHistroy] = React.useState([]);

  let  Chatnumber =  localStorage.getItem('checkinflag')
  // const today = new Date().toLocaleDateString();

  const now = new Date();
  const threeHours = 3 * 60 * 60 * 1000; 
  // 获取存储的日期
  const lastDate = localStorage.getItem('lastDate');
  // 间隔3个小时清除缓存
 
  if (!lastDate || now - new Date(lastDate) > threeHours) {
    localStorage.removeItem('lastDate')
    localStorage.removeItem('checkinflag')
    localStorage.setItem('lastDate', now);
    setcheckInstatus(0)
  }

  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;


  const getChatList = async (item) => {
    let address =  props.data.address
    const res = await axios.get(`https://api.aiworld.xyz/users/${address}`);
    const { data } = res
     if(res.data) {
          if(res.data.cnt){
            React.$cnt = res.data.cnt
            setCheckinCnt(res.data.cnt)
          }else{
            React.$cnt = 0
            setCheckinCnt(0)
          }        
      }
      const checkins = await axios.get(`https://api.aiworld.xyz/checkins/${address}`);
      const arrays  = checkins.data
      const arr = arrays.map((v, i) => {
          return v.checkInDate;
      });
      setCheckinsHistroy(arr);
  };
  useEffect(() => {
      if(props.data.address){
        getChatList(props.data.address);
      }else{
        setCheckinCnt(0)
      }
  }, [props.data.address]); // Call getChatList only once when the component mount



  function formatDate(d) {
    var date = new Date(d);
    var YY = date.getFullYear();
    var MM =
      date.getMonth() + 1 < 10
        ? "0" + (date.getMonth() + 1)
        : date.getMonth() + 1;
    var DD = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    return `${YY}-${MM}-${DD}`;
  }

  function getTileClassName({ date, view }) {
    // 判断当前日期是否为今天
    // console.log(date)
    // if (view === 'month' && date.getDate() === new Date().getDate()) {
    //   if(localStorage.getItem('checkinflag')==1){
    //     return 'Checkintoday';
    //   }    
    // } 
    if (checkinsHistroy.includes(formatDate(date))) {
      return "Checkintoday";
    }
    return null;
  }

  function Checkin() {
    const headers = new Headers();
    headers.append("Content-Type", "application/json"); 
    if (props.data && props.data.address) {
      fetch(
        "https://api.aiworld.xyz/checkin",
        {
          method: "POST",
          headers,
          body: JSON.stringify({
            address: props.data.address,
            timezone:timeZone,
          }),
        }
      )
        .then((response) => {
          if(response.status === 200){         
            localStorage.setItem('checkinflag', 1)
            setcheckInstatus(1)
            getTileClassName({ date: new Date(), view: 'month' });
            message.open({
              type: 'success',
              content: React.$i18n("UserCalendars.SignTip"),
              className: 'userTip-mess',
              duration:3
            });  
           }else if(response.status === 400){
            localStorage.setItem('checkinflag', 1)
            setcheckInstatus(1)
            getTileClassName({ date: new Date(), view: 'month' }); 
            message.open({
              type: 'error',
              content: React.$i18n("UserCalendars.already"),
              className: 'userTip-mess',
              duration:3
            });  
           }
        })
        .then((data) => {
          // console.log(data)
          if (data.error) {
            message.open({
              type: 'error',
              content: `Sorry, adress ${props.data.address}, ${data.error}`,
              className: 'userTip-mess',
              duration:3
            });  
          } else {
            localStorage.setItem('checkinflag', 1)
            setcheckInstatus(1)
            getTileClassName({ date: new Date(), view: 'month' });
            message.open({
              type: 'success',
              content: `Your adress ${data.address} total checkin count is ${data.cnt}`,
              className: 'userTip-mess',
              duration:3
            });  
          }
        })
        .catch((error) => {        
          console.error(error);
        
        });
    } else {}
  }


  return (
       <div  className={`${styles["container"]}`}> 
          <UsersHeader></UsersHeader>
          <div style={{ display: isphone ? "none" : "block" }} className={`${styles["backUserSignIn"]}`} onClick={()=>{
            back()
           }}> {'<back'} </div> 
           <div className={`${styles["UserCalendars"]}`}>              
            <div className={`${styles["timeheader"]}`}>
              <div className={`${styles["timeheader-left"]}`}> </div>
             
              <div className={`${styles["timeheader-right"]}`}   onClick={()=>{
                   const createInput = document.createElement('input');
                   createInput.value = props.data.address;
                   document.body.appendChild(createInput);
                   createInput.select();
                   document.execCommand('Copy'); // document执行复制操作
                   createInput.remove();
              }} >{React.$i18n("UserCalendars.Current")}<span className={`${styles["timeheader-num"]}`}>{React.$cnt || checkinCnt}</span></div>
           
            </div>
            </div> 
        
      
     <div className={`${styles["react-calendar"]}`}>  
      <Calendar value={date} activeStartDate={date}    onChange={handleDateChange} tileClassName={getTileClassName}  locale="en-US" />
    </div>


        <div className={`${styles["Signed"]}`}>{React.$i18n("UserCalendars.Signeday")} <span className={`${styles["timeheader-num"]}` }>{checkinsHistroy.length || 0}</span> {React.$i18n("UserCalendars.Days")}</div>
           <button
          className={`${styles["CONNECT"]} flex-col align-center justify-center` + ' ' + (checkInstatus==1?`${styles["checkIned"]}`:'') }
          disabled={!props.data.address || checkInstatus==1}
          onClick={() => {
            Checkin()
          }}
        >
        { checkInstatus==1 ?React.$i18n("UserCalendars.SignedBtn"):React.$i18n("UserCalendars.Checkbtn")}    
          </button>

          <UsersFooter></UsersFooter>
       </div>
  );
}
export default UserCalendars;
