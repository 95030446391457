import React, { Component } from "react";
import { Space, Swiper } from "antd-mobile";
import styles from "../assets/css/UserWallet.module.css";
import { Link,useHistory} from "react-router-dom";
import {UsersHeader,UsersFooter} from "../components";
import { CLOUD_API_URL } from "../constants";
import { Button, message, Modal, Toast, Divider  } from 'antd';
function UserSignIn(props) {
  const [menuShow, setMenuShow] = React.useState(false);
  const [UserInfosohw, setUserInfosohw] = React.useState(false);
  const [inputvalue, setInputvalue] = React.useState("");
  const history = useHistory()
  const item = [
    {image:"../assets/image/user1.jpg"},
    {image:"../assets/image/user2.jpg"},
    {image:"../assets/image/user3.jpg"},
  ]
  function handleClick(){
    history.push({pathname: '/UserSignIn', state: { }})
  }

  const state = {
    isphone: false,
    MetaMaskshow: false,
  };
  function browserRedirect() {
    var sUserAgent = navigator.userAgent.toLowerCase();
    var bIsIpad = sUserAgent.match(/ipad/i) == "ipad";
    var bIsIphoneOs = sUserAgent.match(/iphone os/i) == "iphone os";
    var bIsMidp = sUserAgent.match(/midp/i) == "midp";
    var bIsUc7 = sUserAgent.match(/rv:1.2.3.4/i) == "rv:1.2.3.4";
    var bIsUc = sUserAgent.match(/ucweb/i) == "ucweb";
    var bIsAndroid = sUserAgent.match(/android/i) == "android";
    var bIsCE = sUserAgent.match(/windows ce/i) == "windows ce";
    var bIsWM = sUserAgent.match(/windows mobile/i) == "windows mobile";
    if (
      bIsIpad ||
      bIsIphoneOs ||
      bIsMidp ||
      bIsUc7 ||
      bIsUc ||
      bIsAndroid ||
      bIsCE ||
      bIsWM
    ) {
      //跳转移动端页面
      state.isphone = true;
    } else {
      //跳转pc端页面
      state.isphone = false;
    }
  }
  browserRedirect();


  const handleInputChange = (event) => {
    const { value } = event.target;
    setInputvalue(value);
  };

  const bindAccount = async () => {
    let postData = {};
    if(!inputvalue || inputvalue<1){
      message.open({
        type: 'error',
        content:React.$i18n('UserSignIn.UIDTip'),
        className: 'userTip-mess',
        duration:3
      });
      return;
    }
    postData = {
      uid: inputvalue,
    };

    postData = { ...postData, address: props.data.address };

    try {
      const response = await fetch(`${CLOUD_API_URL}/social-media`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(postData),
      }).then(res=>{
        if(res.status== 200 ){
            setUserInfosohw(!UserInfosohw);
            React.$uid = inputvalue
            setInputvalue('');
            message.open({
              type: 'success',
              content:React.$i18n('UserSignIn.BindingTip'),
              className: 'userTip-mess',
              duration:3
            });
        }else{
          message.error({
            type: 'error',
            content:  React.$i18n('UserSignIn.Bindingfail'), 
            className: 'userTip-mess',
            duration:3
          });
        }
      }).catch(err=>{
        message.error({
          type: 'error',
          content: React.$i18n('UserSignIn.Bindingfail'), 
          className: 'userTip-mess',
          duration:3
        });
      });
    }catch(error) {
      console.error("Error fetching data:", error);
    }
  };


  return (
       <div  className={`${styles["container"]}`}>         
       <UsersHeader></UsersHeader>
         <div className={`${styles["container_box"]}`}>   
         <div className={`${styles["imgbox"]}`} onClick={() => {
            history.push({pathname: '/UserCalendars', state: {}})
          }}>
           <img
              className={`${styles["userimg"]}`}
              src={require(`../assets/image/user1.jpg`).default}
            />
           </div>
           <div className={`${styles["imgbox"]}`} onClick={() => {
            history.push({pathname: '/BindAccount', state: { }})
          }}>
           <img
              className={`${styles["userimg"]}`}
              src={require(`../assets/image/user2.jpg`).default}
            />
           </div>
           <div className={`${styles["imgbox"]}`} onClick={() => {
             history.push({pathname: '/UserFollow', state: { }})            
          }}>
           <img
              className={`${styles["userimg"]}`}
              src={require(`../assets/image/user3.jpg`).default}
            />
           </div>
        </div>


        <div className={`${styles["container_box2"]}`}> 
        <div className={`${styles["imgbox"]}`} onClick={() => {
             history.push({pathname: '/UserInvite', state: { }})            
          }}>
        {state.isphone?   <img
              className={`${styles["userimg"]}`}
              src={require(`../assets/image/Invite.jpg`).default}
            />: <img
            className={`${styles["userimg"]}`}
            src={require(`../assets/image/pcyq.jpg`).default}
          />
        }
        </div>

      
           <div className={`${styles["Points"]}`} onClick={() => {}}>
           <img
              className={`${styles["jf"]}`}
              src={require(`../assets/image/jf.png`).default}
            />
            <div  className={`${styles["PointsNum"]}`} >{React.$i18n('UserSignIn.MyPoints')}{ React.$cnt || 0}</div>
            
          <Button className={`${styles["PointBtn"]}`} size='large' color='warning' onClick={() => {
             if(React.$uid && React.$uid!=''){
              history.push({pathname: '/Points', state: { }}) 
             }else{
              setUserInfosohw(true);
             }           
          }}>
            {React.$i18n('UserSignIn.exchange')}
          </Button>    
           </div>
        </div>

        <div className={`${styles["uid_tip"]}`}>
         {React.$i18n('UserSignIn.problems')}        
        </div>

        <div
        class="UserInformation"
        style={{ display: UserInfosohw ? "block" : "none" }}
      >
        <div className={`${styles["bindbox"]}`}>
          <img
            class="closeInformation"
            onClick={() => {setUserInfosohw(!UserInfosohw)}}
            src={
              require("../assets/img/SketchPnge6b0562b3e1068bfcdd385f9e0568600ad5816771f1ba1ae906f13cbbc55c45b.png")
                .default
            }
          />
          <div class="UserIntest">
            <div className={`${styles["bindtitle"]}`}>{React.$i18n('UserSignIn.exchange')}</div>
            <div class="UserInfotext">
              <div className={`${styles["AccountNumber"]}`}>{React.$i18n('UserSignIn.BindUID')}</div>
              <input
                className={`${styles["Accountinput"]}`}
                name="twitter"
                value={inputvalue}
                onChange={handleInputChange}
                type="number"
                pattern="[0-9]*"
              />
            </div>
            {React.$i18n('UserSignIn.already')}
            <button
              className={`${styles["button_1"]} flex-col align-center justify-center`}
              onClick={() => {
                bindAccount();
              }}
            >
              {React.$i18n('UserSignIn.BindUID')}  
            </button>
          </div>
        </div>
      </div>


           <UsersFooter></UsersFooter>
       </div>
  );
}

export default UserSignIn;
