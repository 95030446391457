import React, { Component, useRef } from "react";
import { Space, Swiper } from "antd-mobile";
import { Contract } from "ethers";

import html2canvas from "html2canvas";

import styles from "../assets/css/wallet.module.css";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import { UsersHeader } from "../components";
import NFT_ABI from "../contracts/ainft_contract";
import { CLOUD_API_URL, UPLOAD_IMAGE_URL } from "../constants";

const chatHistoryContainerId = "chat-history";

const captureChatHistory = async () => {
  const chatHistoryElement = document.getElementById(chatHistoryContainerId);
  const canvas = await html2canvas(chatHistoryElement);
  const imageDataUrl = canvas.toDataURL("image/png");
  return imageDataUrl;
};

const uploadImage = async (imageDataUrl) => {
  const apiUrl = UPLOAD_IMAGE_URL;
  const response = await axios.post(apiUrl, { image: imageDataUrl });
  return response.data.imageUrl;
};


const shareOnTwitter = (imageUrl) => {
  const text = "Check out chatgpt message:";
  const encodedText = encodeURIComponent(text);
  const encodedImageUrl = encodeURIComponent(imageUrl);
  const twitterUrl = `https://twitter.com/intent/tweet?text=${encodedText}&url=${encodedImageUrl}`;
  window.open(twitterUrl, "_blank");
};

const handleShareChatHistory = async () => {
  const imageDataUrl = await captureChatHistory();
  const imageUrl = await uploadImage(imageDataUrl);
  shareOnTwitter(imageUrl);
};

function Chat(prop) {
  // console.log("chat----------------",prop.data) 
  let Chatnumber = localStorage.getItem("Chatnumber");
  const today = new Date().toLocaleDateString();
  // 获取存储的日期
  const lastClearDate = localStorage.getItem("lastClearDate");
  // 如果存储的日期不是今天的日期，则清空缓存
  if (lastClearDate !== today) {
    localStorage.removeItem("lastClearDate");
    localStorage.removeItem("Chatnumber");
    localStorage.setItem("lastClearDate", today);
  }

  if (!Chatnumber && Chatnumber != 0) {
    if (prop.data.address) {
      localStorage.setItem("Chatnumber", 10);
    } else {
      localStorage.setItem("Chatnumber", 5);
    }
  }

  const history = useHistory();
  const [status, setStatus] = React.useState("mintNFT loading");
  const [showMessage, setShowMessage] = React.useState(false);
  const [text, setText] = React.useState("");
  const [userId, setUserId] = React.useState("");
  const [chatType, setChatType] = React.useState(1);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isLoading2, setIsLoading2] = React.useState(false);


  const [chatData, setChatData] = React.useState([{ ai: true, message:React.$i18n('Chat.firstWORLD') },]);

let HistoricalChat = localStorage.getItem('HistoricalChat')
if(HistoricalChat){
    HistoricalChat = JSON.parse(HistoricalChat)
    // console.log(HistoricalChat)  
}
  const messagesEndRef = useRef(null);
  const inputRef = useRef(null);
  React.useEffect(() => {
    setUserId(`${Math.random(10000)}`);
    if(HistoricalChat){
    setChatData(HistoricalChat);
    }
  }, []);

  function backhome() {
    history.push({ pathname: "/", state: {} });
  }

  let address =  prop.data.address || React.$userinfo.address || sessionStorage.getItem("address");

  const handleTextChange = (event) => {
    setText(event.target.value);
  };

  async function mintNFT(imgUrl, question) {
    // console.log(imgUrl);
    if (
      address == "" ||
      address == null ||
      address == undefined ||
      address == "undefined"
    ) {
      React.$i18n('Chat.firstWORLD')
      setStatus(React.$i18n('Chat.logTip'), address);
      setShowMessage(true);
      return;
    }
    setStatus("loading...");
    setShowMessage(true);
    const contractAddress = "0xf5Aa5FA8e853eCF5a69fD46d409e49A5242aBE5C";
    if (!prop.data.mainnetProvider || !prop.data.userSigner) {
      setStatus(React.$i18n('Chat.connect')); 
      return;
    }
    if (!imgUrl) {
      setStatus(React.$i18n('Chat.entertoken')); 
      return;
    }
    setStatus(React.$i18n('Chat.Minting'));
    // console.log(status);
    const contract = new Contract(
      contractAddress,
      NFT_ABI,
      prop.data.userSigner
    );
    try {
      const response = await fetch(`${CLOUD_API_URL}/generate-metadata`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          url: imgUrl,
          description: question || "Ai Generate Image", // todo: change to associated text
        }),
      });
      // .then(res=>{
      // if(res.status== 200 ){
      //     setUserInfosohw(!UserInfosohw);
      //     setInputvalue('');
      //     setIndex("")
      // }
      // });
      // console.log(response);
      const res = await response.json();
      // console.log(responseData);
      // setData(responseData);
      const tokenURI = `https://api.aiworld.xyz/metadata/${res.id}`;
      const transaction = await contract.mintNFT(
        prop.data.userSigner.getAddress(),
        tokenURI
      );
      await transaction.wait();     
      setStatus(`Success`);
      setShowMessage(true);
      // fetch(`https://api.aiworld.xyz/count`, {
      //   method: "POST",
      //   headers: {
      //     "Content-Type": "application/json",
      //   },
      //   body:JSON.stringify({
      //     address: address,
      //   }),
      // }).then(()=>{
      //     setStatus(`Success`);
      //     setShowMessage(true);
      // });   
    } catch (error) {
      console.log(error);
      setStatus(`Error: ${error.message}`);
      setShowMessage(true);
    }
  }

  const handleSendClick = async () => {
    // props.onSend(text);
    // setText('');
   if(text==''){
    setStatus("Please input first");
    setShowMessage(true);
    setIsLoading2(false)
    return
   }

    const input = inputRef.current;
    setChatData([...chatData, ...[{ ai: false, message: text }]]);
    setText("");
    getChatList([...chatData, ...[{ ai: false, message: text }]]);
    input.innerText = "";
    input.style.height = "auto";
    input.style.height = input.scrollHeight + "px";
    setTimeout(() => {
      messagesEndRef.current.scrollTop =
        messagesEndRef.current.scrollHeight + 100;
    }, 500);
  };
  const userLocalTime = new Date().toISOString();
  const getChatList = async (item) => {
    let url = "https://aiapi.tuikehome.com/request/chatgpt";
    let updata = {};

    if (
      address != "" &&
      address != null &&
      address != undefined &&
      address != "undefined"
    ) {
      // console.log(typeof address, address);
      url = "https://api.aiworld.xyz/gpt";
      updata = {
        question: text,
        resType: chatType,
        imgSize: 1024,
        address: address,
        userLocalTime: userLocalTime,
      };
    } else {
      url = "https://aiapi.tuikehome.com/request/chatgpt";
      updata = {
        question: text,
        reqType: chatType,
        userId: userId,
      };
    }
    // const res = await axios.post("https://aiapi.tuikehome.com/request/chatgpt", {
    //   // "deviceType": 0,
    //   // "featureDesc": "",
    //   // "gptFrequencyPenalty": 0,
    //   // "gptModel": "",
    //   // "gptPresencePenalty": 0,
    //   // "gptTemperature": 0,
    //   "question": text,
    //   "reqType": chatType,
    //   "userId": userId
    // })
    // console.log('xxxx', res.data.data);
    const res = await axios.post(url, updata).catch((err) => {
      // console.log("1111111111111111111",err)
      setChatData([
        ...item,
        ...[
          {
            ai: true,
            message:React.$i18n('Chat.insufficient'),
            chatType: false,
          },
        ],
      ]);
      setTimeout(() => {
        messagesEndRef.current.scrollTop =
          messagesEndRef.current.scrollHeight + 100;
      }, 500);
      setIsLoading2(false)
      return err;
    });
    const { data } = res;
    if (data && data.code == 2000) {
      Chatnumber -= 1;
      localStorage.setItem("Chatnumber", Chatnumber);
      // console.log("chatData", item);
      setChatData([
        ...item,
        ...[
          {
            ai: true,
            message: data.data,
            chatType: +chatType === 2 ? true : false,
            question: text,
          },
        ],
      ]);
      // console.log(data.data, chatData);
      setTimeout(() => {
        messagesEndRef.current.scrollTop =
          messagesEndRef.current.scrollHeight + 200;
      }, 500);
      setIsLoading2(false)
    }
  };
  const openPhoto = (url) => {
    window.open(url);
  };
  const [selectedOption, setSelectedOption] = React.useState("");
  function handleOptionChange(event) {
    console.log(event.target.value, selectedOption);
    if (selectedOption == "") {
      setSelectedOption(1);
      setChatType(2);
    } else {
      setSelectedOption("");
      setChatType(1);
    }
  }

  function handleKeyDown(event) {
    if (event.keyCode === 13) {
      // 执行回车事件的操作
      handleSendClick();
    }
  }
  React.useEffect(() => {
    const input = inputRef.current;
    const handleInput = () => {
      input.style.height = "auto";
      input.style.height = input.scrollHeight + "px";
      setText(input.innerText);
    };
    input.addEventListener("input", handleInput);
    return () => {
      input.removeEventListener("input", handleInput);
    };
  }, []);

  React.useEffect(() => {
    if (showMessage) {
      setTimeout(() => {
        setShowMessage(false);
      }, 3000);
    }
  }, [showMessage]);

  React.useEffect(() => {
    return () => {
      // Code to handle page destruction event
      // console.log(chatData)
      if(chatData.length>6){
          let data = chatData.slice(-6);   
          localStorage.setItem('HistoricalChat',JSON.stringify(data));
       }else{
          localStorage.setItem('HistoricalChat',JSON.stringify(chatData));
       }
      
    };
  }, [chatData]);

  return (
    <div className={`${styles["chatcontainer"]}`}>
      <UsersHeader></UsersHeader>
      <div className={`${styles["chatbox"]}`}>
        <div
          id={`${chatHistoryContainerId}`}
          className={`${styles["chatcontent"]}`}
          ref={messagesEndRef}
        >
          {showMessage && <div className={`${styles["popup"]}`}>{status}</div>}

          {chatData.map((item, index) => (
            <div
              kye={index}
              className={`${
                item.ai
                  ? styles["oneselfBackground"]
                  : styles["myselfBackground"]
              }`}
            >
              <div
                className={`${item.ai ? styles["oneself"] : styles["myself"]}`}
              >
                {+item.chatType ? (
                  <div className={`${styles["imgbox"]}`}>
                    <img
                      src={item.message}
                      style={{ width: "100%", height: "100%" }}
                      onClick={() => openPhoto(item.message)}
                    />{" "}
                    <div
                     style={{ display: item.isclick ? "none" : "block" }}                    
                      className={`${styles["nft"]}`}
                      onClick={() =>{
                        item.isclick = true
                        mintNFT(item.message, item.question)
                      }}
                    >
                      mintNFT
                    </div>{" "}
                  </div>
                ) : (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: item.message.replace(
                        new RegExp("\n", "g"),
                        "<br/>"
                      ),
                    }}
                  ></div>
                )}
              </div>
            </div>
          ))}
        </div>
        <div className={`${styles["askinput"]}`}>
          <div
            className={
              selectedOption == "1"
                ? `${styles["askinput_header2"]}`
                : `${styles["askinput_header"]}`
            }
            onClick={handleOptionChange}
          >
            <input
              type="radio"
              value={"1"}
              className={`${styles["askinput_radio"]}`}
              checked={selectedOption == "1"}
            />
            <span>{React.$i18n('Chat.Image')}</span>
          </div>
          <button  disabled={isLoading}  className={`${styles["ShareBtn"]}`} onClick={() =>{
           if (window.chrome) {
            setIsLoading(true)
            setTimeout(() => {
             setIsLoading(false)
           }, 3000);
              handleShareChatHistory()
          } else {
            setStatus(React.$i18n('Chat.GoogleTip')) ;
            setShowMessage(true);
          }
         
          }}>
            {isLoading ? React.$i18n('Chat.Loading'): React.$i18n('Chat.Share')}
          </button>
          {/* <input className={`${styles["textinput"]}`} style={{whiteSpace:'pre-wrap'}}  value={text} onChange={handleTextChange} onKeyDown={handleKeyDown} />        */}
          <div
            className={`${styles["textinput"]}`}
            contentEditable={true}
            value={text}
            onChange={handleTextChange}
            ref={inputRef}
          ></div>
          <button disabled={isLoading2}  className={`${styles["sendbtn"]}`} onClick={() =>{
             setIsLoading2(true)
              setTimeout(() => {
              setIsLoading2(false)
              }, 5000);
            handleSendClick()
          }}>
           {isLoading2 ? React.$i18n('Chat.Loading') : React.$i18n('Chat.Send')}  
          </button>
        </div>
        <div></div>
      </div>
    </div>
  );
}

export default Chat;
