import React, { Component, useEffect, useState } from "react";
import { Space, Swiper } from "antd-mobile";
import * as bootstrap from "react-bootstrap";
import styles from "../assets/css/UserWallet.module.css";
import { Link, useHistory } from "react-router-dom";
import { UsersHeader, UsersFooter } from "../components";
import { Button, message } from "antd";
import { Contract } from "ethers";
import AI_NEW_NODE_ABI from "../contracts/ainewnode_contracts";

function Mynodes(props) {
  // console.log(props);

  const contract = new Contract(
    props.data.contractAddress,
    AI_NEW_NODE_ABI,
    props.data.userSigner
  );

  


  async function getUserNodes(userAddress) {
    console.log(`address is ${userAddress}`);
    const nodeCount = await contract.getNodeDetailsLength(userAddress);
    const nodes = [];
    for (let i = 0; i < nodeCount; i++) {
      const node = await contract.getNodeDetails(userAddress, i);
      nodes.push(node);
    }
    console.log(nodes);
    return nodes;
  }

  const [myNodes, setMyNodes] = useState([]);



  
// const contractAddress = props.data.contractAddress;
//   async function getnodes() {
//     const referAddress = "";
//     if (!props.data.mainnetProvider || !props.data.userSigner) {
//       return;
//     }
//     const contract = new Contract(
//       contractAddress,
//       AI_NEW_NODE_ABI,
//       props.data.userSigner
//     );
//     const soldNum = await contract.nodesSold(); //卖出多少个node in total
//     const nomalNodeSold = await contract.normalNodesSold(); // how many normal nodes always +3500 airdropped node
//     const priceOfClusterNode = "10000"; // 集群节点 price
//     const priceOfMainNode = "5000"; //  主要节点 price
//     const priceOfNode = await contract.normalNodePrice(); //价格

//     // let a = parseInt(soldNum._hex, 16);
//     // let b = parseInt(priceOfNode._hex, 16) / 1000000000000000000;
//     // let bfb = (soldNum / 20000).toFixed(1);
//     // if (bfb < 100) {
//     //   setProgress(bfb);
//     // }
//   }



  React.useEffect(async () => {
    console.log("33333333",props.data.address);
    const nodes = await getUserNodes(props.data.address);
    setMyNodes(nodes);
    // getnodes()
  }, [props.data.mainnetProvider, props.data.address]);

  const state = {
    isphone: false,
    MetaMaskshow: false,
  };
  function browserRedirect() {
    var sUserAgent = navigator.userAgent.toLowerCase();
    var bIsIpad = sUserAgent.match(/ipad/i) == "ipad";
    var bIsIphoneOs = sUserAgent.match(/iphone os/i) == "iphone os";
    var bIsMidp = sUserAgent.match(/midp/i) == "midp";
    var bIsUc7 = sUserAgent.match(/rv:1.2.3.4/i) == "rv:1.2.3.4";
    var bIsUc = sUserAgent.match(/ucweb/i) == "ucweb";
    var bIsAndroid = sUserAgent.match(/android/i) == "android";
    var bIsCE = sUserAgent.match(/windows ce/i) == "windows ce";
    var bIsWM = sUserAgent.match(/windows mobile/i) == "windows mobile";
    if (
      bIsIpad ||
      bIsIphoneOs ||
      bIsMidp ||
      bIsUc7 ||
      bIsUc ||
      bIsAndroid ||
      bIsCE ||
      bIsWM
    ) {
      //跳转移动端页面
      state.isphone = true;
    } else {
      //跳转pc端页面
      state.isphone = false;
    }
  }
  browserRedirect();

  const history = useHistory();
  function back() {
    history.push({ pathname: "/UserSignIn", state: {} });
  }
  function copyLink() {
    const createInput = document.createElement("input");
    createInput.value = `https://aiworld.xyz/?share=${props.data.address}`;
    document.body.appendChild(createInput);
    createInput.select();
    document.execCommand("Copy"); // document执行复制操作
    createInput.remove();
    message.open({
      type: "success",
      content: "Successfully copied invitation link",
      className: "userTip-mess",
      duration: 3,
    });
  }

  return (
    <div className={`${styles["container"]}`}>
      <UsersHeader></UsersHeader>
      <div
        style={{ display: state.isphone ? "none" : "block" }}
        className={`${styles["backUserSignIn"]}`}
        onClick={() => {
          back();
        }}
      >
        {" "}
        {"<  back"}{" "}
      </div>

      <div className={`${styles["mynodebody"]}`}>
        <img
          className={`${styles["BindAccount"]}`}
          src={require(`../assets/image/AIWorldNodes.png`).default}
        />

        <div className={`${styles["AIWorldNodes"]}`}>
          <div className={`${styles["Numbernodes"]}`}>
            <img
              className={`${styles["nodeimg"]}`}
              src={require(`../assets/image/a1.png`).default}
            />
            <div className={`${styles["nodesTip"]}`}>
              {React.$i18n("MyNodes.TotalNodes")}
            </div>
            <div className={`${styles["nodesNum"]}`}>--</div>
          </div>
          <div className={`${styles["Numbernodes"]}`}>
            <img
              className={`${styles["nodeimg"]}`}
              src={require(`../assets/image/a2.png`).default}
            />
            <div className={`${styles["nodesTip"]}`}>
              {React.$i18n("MyNodes.Production")}
            </div>
            <div className={`${styles["nodesNum"]}`}>--</div>
          </div>
          <div className={`${styles["Numbernodes"]}`}>
            <img
              className={`${styles["nodeimg"]}`}
              src={require(`../assets/image/a3.png`).default}
            />
            <div className={`${styles["nodesTip"]}`}>
              {React.$i18n("MyNodes.Effective")}
            </div>
            <div className={`${styles["nodesNum"]}`}>--</div>
          </div>
        </div>

        <img
          className={`${styles["BindAccount"]}`}
          src={require(`../assets/image/mynodes.png`).default}
        />

        <div className={`${styles["NodeRevenue"]}`}>
          <div className={`${styles["TokenVolume_top"]}`}>
            <div className={`${styles["TokenVolume_title"]}`}>
              {React.$i18n("MyNodes.Revenue")}
            </div>
            <div className={`${styles["TokenVolume_Num"]}`}>--</div>
            <button
              className={`${styles["UserInvBtn"]}`}
              style={{ margin: "auto", marginTop: "15px" }}
              onClick={() => {
                message.open({
                  type: "warning",
                  content: React.$i18n("AiwordGlobalL.Comingsoon"),
                  className: "userTip-mess",
                  duration: 3,
                });
              }}
            >
              CHECK
            </button>
            <div className={`${styles["nodeline"]}`}></div>
          </div>
          <div className={`${styles["TokenVolume_top"]}`}>
            <div className={`${styles["TokenVolume_title"]}`}>
              {React.$i18n("MyNodes.Mynodes")}
            </div>
            <div className={`${styles["TokenVolume_Num"]}`}>--</div>
            <button
              className={`${styles["UserInvBtn"]}`}
              style={{ margin: "auto", marginTop: "15px" }}
              onClick={() => {
                message.open({
                  type: "warning",
                  content: React.$i18n("AiwordGlobalL.Comingsoon"),
                  className: "userTip-mess",
                  duration: 3,
                });
              }}
            >
              CHECK
            </button>
          </div>
        </div>

        <div className={`${styles["TokenVolume"]}`}>
          <div className={`${styles["TokenVolume_top"]}`}>
            <div className={`${styles["TokenVolume_title"]}`}>
              {React.$i18n("MyNodes.Volume")}
            </div>
            <div className={`${styles["TokenVolume_Num"]}`}>--</div>
            <div className={`${styles["TokenVolume_cen"]}`}>
              <div className={`${styles["Available"]}`}>
                <div className={`${styles["Available_top"]}`}>
                  {React.$i18n("MyNodes.Released")}
                </div>
                <div className={`${styles["Available_bot"]}`}>--</div>
              </div>
              <div className={`${styles["Freeze"]}`}>
                <div className={`${styles["Freeze_top"]}`}>
                  {React.$i18n("MyNodes.Unreleased")}
                </div>
                <div className={`${styles["Freeze_bot"]}`}>--</div>
              </div>
            </div>
            <button
              className={`${styles["UserInvBtn"]}`}
              style={{ margin: "auto", marginTop: "15px" }}
              onClick={() => {
                message.open({
                  type: "warning",
                  content: React.$i18n("AiwordGlobalL.Comingsoon"),
                  className: "userTip-mess",
                  duration: 3,
                });
              }}
            >
              WITHDRAW
            </button>
            <div className={`${styles["nodeline"]}`}></div>
          </div>
          <div className={`${styles["TokenVolume_top"]}`}>
            <div className={`${styles["TokenVolume_title"]}`}>
              {React.$i18n("MyNodes.redeemed")}
            </div>
            <div className={`${styles["TokenVolume_Num"]}`}>--</div>
            <div className={`${styles["TokenVolume_cen"]}`}>
              <div className={`${styles["Available"]}`}>
                <div className={`${styles["Available_top"]}`}>
                  {React.$i18n("MyNodes.Available")}
                </div>
                <div className={`${styles["Available_bot"]}`}>--</div>
              </div>
              <div className={`${styles["Freeze"]}`}>
                <div className={`${styles["Freeze_top"]}`}>
                  {React.$i18n("MyNodes.Freeze")}
                </div>
                <div className={`${styles["Freeze_bot"]}`}>--</div>
              </div>
            </div>
            <button
              className={`${styles["UserInvBtn"]}`}
              style={{ margin: "auto", marginTop: "15px" }}
              onClick={() => {
                message.open({
                  type: "warning",
                  content: React.$i18n("AiwordGlobalL.Comingsoon"),
                  className: "userTip-mess",
                  duration: 3,
                });
              }}
            >
              EXCHANGE
            </button>
          </div>
        </div>

        <div className={`${styles["myNowinvite"]}`}>
          <div className={`${styles["InviteFriends"]}`}>
            <img
              className={`${styles["InviteFriend"]}`}
              src={require(`../assets/image/InviteFriend.png`).default}
            />
          </div>
          <div className={`${styles["Nowpoints"]}`}>
            <div className={`${styles["Nowpoints_top"]}`}>
              {React.$i18n("MyNodes.invite1")} <br />{" "}
              {React.$i18n("MyNodes.invite2")}
            </div>
            <button
              className={`${styles["UserInvBtn"]}`}
              onClick={() => {
                copyLink();
              }}
            >
              {React.$i18n("MyNodes.InviteFriends")}
            </button>
          </div>
        </div>

        {/* <img
        className={`${styles["BindAccount"]}`}
        src={require(`../assets/image/AIWorldNodes.png`).default}
      />   */}
      </div>
      <UsersFooter></UsersFooter>
    </div>
  );
}
export default Mynodes;
