import React, { Component, useEffect } from "react";
import { Contract, utils } from "ethers";
import { Space, Swiper } from "antd-mobile";
import * as bootstrap from "react-bootstrap";
import styles from "../assets/css/UserWallet.module.css";
import { Link, useHistory } from "react-router-dom";
import { UsersHeader, UsersFooter } from "../components";
import { CLOUD_API_URL } from "../constants";
import { Button, message, Input } from "antd";
import axios from "axios";

import AIRDROP_ABI from "../contracts/airdrop_contract";

function Publicoffering(props) {
  const [menuShow, setMenuShow] = React.useState(false);
  const [disabled, setdisabled] = React.useState(false);
  const [countdownfalg, setcountdownfalg] = React.useState(false);
  const [bindinfo, setbindinfo] = React.useState({ title: "Ioannis Korkos" });
  const [Accounttitle, setbindtitle] = React.useState({
    title: "Account Number",
  });
  const [UserInfosohw, setUserInfosohw] = React.useState(false);
  const [inputvalue, setInputvalue] = React.useState("");
  const [tokenNUm, SettokenNUm] = React.useState([]);
  const [InvolvementList, setInvolvementList] = React.useState([1]);

  const [amountOfTokens, setAmountOfTokens] = React.useState("0");

  const contractAddress = "0x32BA9edA11d73886cBa89a5BE475E8866Ef14Af1";
  const contract = new Contract(
    contractAddress,
    AIRDROP_ABI,
    props.data.userSigner
  );

  function back() {
    history.push({ pathname: "/UserSignIn", state: {} });
  }

  let isphone = React.$isphone || sessionStorage.getItem("isphone");

  const handleInputChange = (event) => {
    const { value } = event.target;
    setInputvalue(value);
  };

  const history = useHistory();

  useEffect(async () => {
    let address = props.data.address;
    if (address) {
      axios
        .get(`https://api.aiworld.xyz/redeem-info/${address}`)
        .then((res) => {
          SettokenNUm(res.data.token_amount);
        });
      //   axios.get(`https://api.aiworld.xyz/pub-offering-timer`,).then(res=>{
      //     console.log("111111111111111111",res)
      // });
      const amt = await contract.whitelist(address);
      setAmountOfTokens(utils.formatEther(amt));
    }
  }, [props.data.address]); // Call getChatList only once when the component mount

  const [days, setDays] = React.useState(0);
  const [hours, setHours] = React.useState(0);
  const [minutes, setMinutes] = React.useState(0);
  const [seconds, setSeconds] = React.useState(0);

  useEffect(() => {
    const countdownDate = new Date("July 30, 2023 00:00:00").getTime();
    const interval = setInterval(() => {
      const now = new Date().getTime();
      const distance = countdownDate - now;
      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);
      setDays(days);
      setHours(hours);
      setMinutes(minutes);
      setSeconds(seconds);
      if (distance < 0) {
        //倒计时结束
        setcountdownfalg(true);
        clearInterval(interval);
        setDays(0);
        setHours(0);
        setMinutes(0);
        setSeconds(0);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  async function Mint() {
    const transaction = await contract.claimTokens();
    await transaction.wait();
  }

  function copyLink() {
    const createInput = document.createElement("input");
    createInput.value = `0x68C9Fd7694E3885C316B81C5daeeFe74C8a35257`;
    document.body.appendChild(createInput);
    createInput.select();
    document.execCommand("Copy"); // document执行复制操作
    createInput.remove();
    message.open({
      type: "success",
      content: "Address copied successfully",
      className: "userTip-mess",
      duration: 3,
    });
  }

  return (
    <div className={`${styles["container"]}`}>
      <UsersHeader></UsersHeader>
      <div
        style={{ display: isphone ? "none" : "block" }}
        className={`${styles["backUserSignIn"]}`}
        onClick={() => {
          back();
        }}
      >
        {" "}
        {"<back"}{" "}
      </div>
      <img
        className={`${styles["BindAccount"]}`}
        src={require(`../assets/image/PublicOffer.png`).default}
      />

      <div className={`${styles["Pointscontainer"]}`}>
        <div class="PointsBox-mc">
          <div className={`${styles["PointsBox-bindbox"]}`}>
            <div class="UserIntest">
              <div className={`${styles["PointsBox-title"]}`}>            
                {React.$i18n('Publicoffering.participation')}
              </div>
              {!countdownfalg ? (
                <div>
                  {/* <div class="UserInfotext">
              <input
                className={`${styles["Accountinput"]}`}
                name="twitter"
                value={inputvalue}
                onChange={handleInputChange}
                placeholder="Please enter the amount"
                type="number"
                pattern="[0-9]*"
                min="1"
                max="10000000"
              >              
              </input>
              <span  className={`${styles["Publicohb"]}`}>U</span>
            </div>           
            <div className={`${styles["tokenNUm"]}`}>Token：{tokenNUm}</div> */}
                  <div className={`${styles["PointsBox-Address"]}`}>
                    {React.$i18n('Publicoffering.Offering')}                            
                    0x68C9Fd7694E3885C316B81C5daeeFe74C8a35257
                  </div>
                  <button
                    disabled={disabled}
                    className={`${styles["button_1"]} flex-col align-center justify-center`}
                    onClick={() => {
                      copyLink();
                    }}
                  >
                    {React.$i18n('Publicoffering.Address')}  
                  </button>
                </div>
              ) : (
                // <div class="UserIntest">
                //   <div className={`${styles["Countdownin"]}`}>Countdown in</div>
                //   <div className={`${styles["countdownDate"]}`}>
                //     <div className={`${styles["Date-list"]}`}>
                //       <div className={`${styles["Date-listtop"]}`}>{days}</div>
                //       <div className={`${styles["Date-listbot"]}`}>DAYS</div>
                //     </div>
                //     <div className={`${styles["colon"]}`}> :</div>
                //     <div className={`${styles["Date-list"]}`}>
                //       <div className={`${styles["Date-listtop"]}`}>{hours}</div>
                //       <div className={`${styles["Date-listbot"]}`}>HOURS</div>
                //     </div>
                //     <div className={`${styles["colon"]}`}> :</div>
                //     <div className={`${styles["Date-list"]}`}>
                //       <div className={`${styles["Date-listtop"]}`}>
                //         {minutes}
                //       </div>
                //       <div className={`${styles["Date-listbot"]}`}>MINUTES</div>
                //     </div>
                //     <div className={`${styles["colon"]}`}> :</div>
                //     <div className={`${styles["Date-list"]}`}>
                //       <div className={`${styles["Date-listtop"]}`}>
                //         {seconds}
                //       </div>
                //       <div className={`${styles["Date-listbot"]}`}>SECONDS</div>
                //     </div>
                //   </div>
                // </div>
                <div>
                <div className={`${styles["PointsBox-Address"]}`}>
                   {React.$i18n('Publicoffering.activity')}  
                </div>
              </div>
              )}
            </div>
          </div>
        </div>

        <div className={`${styles["PublicBox"]}`}>
          <div
            className={`${styles["Publicline"]}`}
            style={{ width: "100%" }}
          ></div>
          <div className={`${styles["PublicBox-title"]}`}>{React.$i18n('Publicoffering.Involvement')}</div>

          <div className={`${styles["PublicBox_con"]}`}>
            <div className={`${styles["friendsList_header"]}`}>
              <div className={`${styles["Publicheader_list"]}`}>{React.$i18n('Publicoffering.Amount')}</div>
              <div className={`${styles["Publicheader_list"]}`}>{React.$i18n('Publicoffering.Token')}</div>
              <div className={`${styles["Publicheader_list"]}`}>{React.$i18n('Publicoffering.Status')}</div>
            </div>
            {InvolvementList.length > 0 ? (
              <div className={`${styles["Public-social"]}`}>
                {InvolvementList.map((item) => (
                  <div className={`${styles["datalist"]}`}>
                    <div className={`${styles["data_list"]}`}>
                      <div className={`${styles["list_div"]}`}>
                        {amountOfTokens}
                      </div>
                      <div className={`${styles["list_div"]}`}>--</div>
                      <div className={`${styles["list_div"]}`}>
                        <button
                          className={`${styles["PublicMint"]}`}
                          onClick={async () => {
                            await Mint();
                          }}
                        >
                          {React.$i18n('Publicoffering.Mint')}
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              ""
            )}
          </div>
        </div>

        <div className={`${styles["Publicline"]}`}></div>

        <div className={`${styles["Publictitle"]}`}>{React.$i18n('Publicoffering.OfferingRules')}</div>

        <div className={`${styles["PubliContent"]}`}>
        {React.$i18n('Publicoffering.offeringtime')}
          <span style={{ color: "#FFD40D" }}>
          {React.$i18n('Publicoffering.StartsTime')}  
          </span>
          {React.$i18n('Publicoffering.offeringprice')}
          <br></br>
          <br></br>
          {React.$i18n('Publicoffering.directly')}        
        </div>

        <div className={`${styles["Publictitle"]}`}>{React.$i18n('Publicoffering.offering')}</div>
        <div className={`${styles["offerContent"]}`}>
          {React.$i18n('Publicoffering.First1')} <br></br>
          {React.$i18n('Publicoffering.First2')} <br></br>
          {React.$i18n('Publicoffering.First3')} <br></br>
          {React.$i18n('Publicoffering.First4')}
        </div>

        <div className={`${styles["Publictitle"]}`}>{React.$i18n('Publicoffering.Operating')}</div>

        <div className={`${styles["PubliContent"]}`}>
          {React.$i18n('Publicoffering.PubliContent1')} <br></br>
          {React.$i18n('Publicoffering.PubliContent2')} <br></br>
          {React.$i18n('Publicoffering.PubliContent3')} <br></br>
          {React.$i18n('Publicoffering.PubliContent4')} <br></br>
          {React.$i18n('Publicoffering.PubliContent5')} <br></br>
          {React.$i18n('Publicoffering.PubliContent6')} <br></br>
          {React.$i18n('Publicoffering.PubliContent7')} 
        </div>
        <div className={`${styles["Publictitle"]}`}>{React.$i18n('Publicoffering.PleaseNote')}</div>
        <div className={`${styles["PubliContent"]}`}>
          {React.$i18n('Publicoffering.AIWorldtoken')}
          <br></br> <br></br>
          {React.$i18n('Publicoffering.publicsale')}      
          <br></br> <br></br>       
          {React.$i18n('Publicoffering.publicends')}  
        </div>
      </div>

      <UsersFooter></UsersFooter>
    </div>
  );
}
export default Publicoffering;
