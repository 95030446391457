import React, { Component, useEffect } from "react";
import { Space, Swiper } from "antd-mobile";
import * as bootstrap from "react-bootstrap";
import styles from "../assets/css/UserWallet.module.css";
import { Link, useHistory } from "react-router-dom";
import { UsersHeader,UsersFooter} from "../components";
import { Button, message } from 'antd';
import axios from "axios";
function UserInvite(props) {
    const state = {
        isphone: false,
        MetaMaskshow: false,
      };
      function browserRedirect() {
        var sUserAgent = navigator.userAgent.toLowerCase();
        var bIsIpad = sUserAgent.match(/ipad/i) == "ipad";
        var bIsIphoneOs = sUserAgent.match(/iphone os/i) == "iphone os";
        var bIsMidp = sUserAgent.match(/midp/i) == "midp";
        var bIsUc7 = sUserAgent.match(/rv:1.2.3.4/i) == "rv:1.2.3.4";
        var bIsUc = sUserAgent.match(/ucweb/i) == "ucweb";
        var bIsAndroid = sUserAgent.match(/android/i) == "android";
        var bIsCE = sUserAgent.match(/windows ce/i) == "windows ce";
        var bIsWM = sUserAgent.match(/windows mobile/i) == "windows mobile";
        if (
          bIsIpad ||
          bIsIphoneOs ||
          bIsMidp ||
          bIsUc7 ||
          bIsUc ||
          bIsAndroid ||
          bIsCE ||
          bIsWM
        ) {
          //跳转移动端页面
          state.isphone = true;
        } else {
          //跳转pc端页面
          state.isphone = false;
        }
      }
      browserRedirect();
  const history = useHistory();
  const [items, setItems] = React.useState([]);

  function back() {
    history.push({ pathname: "/UserSignIn", state: {} });
  }
  function copyLink(){
    const createInput = document.createElement('input');
    createInput.value = `https://aiworld.xyz/?share=${props.data.address}`
    document.body.appendChild(createInput);
    createInput.select();
    document.execCommand('Copy'); // document执行复制操作
    createInput.remove();
    message.open({
      type: 'success',
      content: 'Successfully copied invitation link',
      className: 'userTip-mess',
      duration:3
    });
  }

  useEffect(() => {
    // console.log(props.data.address)
    if(props.data.address){
        axios.get(`https://api.aiworld.xyz/invitation/${props.data.address}`).then((res)=>{
            // console.log("66666666666666666666666666666666",res)
            if(res.data && res.data.referredAddresses){
              res.data.referredAddresses.map(item=>{              
                item.timestamp = getTime(new Date(item.timestamp))
              })
              setItems(res.data.referredAddresses)
             }
          }).catch(err=>{ 
            setItems([])
        });
        }
  }, [props.data.address]); 
 
// 标准时间转换成年月日时分秒（补0）
function getTime(date) {
  let Y = date.getFullYear(),
	M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1),
	D = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate()),
	h = (date.getHours() < 10 ? '0' + (date.getHours()) : date.getHours()),
	m = (date.getMinutes() < 10 ? '0' + (date.getMinutes()) : date.getMinutes()),
	s = (date.getSeconds() < 10 ? '0' + (date.getSeconds()) : date.getSeconds());
  return Y + '-' + M + '-' + D + ' ' + h + ':' + m + ':' + s
}
// console.log(getTime(date)) // 2022-10-31 09:10:22

  return (
    <div className={`${styles["container"]}`}>
      <UsersHeader></UsersHeader>
      <div style={{ display: state.isphone  ? "none" : "block" }} className={`${styles["backUserSignIn"]}`} onClick={()=>{back() }}> {'<  back'} </div>    

 <div  className={`${styles["UserInvitebody"]}`}>
      <img
        className={`${styles["BindAccount"]}`}
        src={require(`../assets/image/UserInvite.png`).default}
      />

     <div className={`${styles["UserInvite_title"]}`}>
      {React.$i18n('UserInvite.Referraltitle')}
     </div>
     <div  className={`${styles["UserInvite_dsc"]}`}>
     {React.$i18n('UserInvite.pointtip')}
     <br/>
     <br/>
     {React.$i18n('UserInvite.pointtip2')}
     </div>

     <div className={`${styles["UserInvite_box"]}`}>
          <div  className={`${styles["UserInvite_list"]}`}>
              <div  className={`${styles["UserInvite_list_left"]}`}>{React.$i18n('UserInvite.Invite')} 5 {React.$i18n('UserInvite.people')}</div>
              <div  className={`${styles["UserInvite_list_right"]}`}>+6 {React.$i18n('UserInvite.points')}</div>
          </div>
          <div  className={`${styles["UserInvite_list2"]}`}>
              <div  className={`${styles["UserInvite_list_left"]}`}>{React.$i18n('UserInvite.Invite')} 10 {React.$i18n('UserInvite.people')}</div>
              <div  className={`${styles["UserInvite_list_right"]}`}>+14 {React.$i18n('UserInvite.points')}</div>
          </div>
          <div  className={`${styles["UserInvite_list"]}`}>
              <div  className={`${styles["UserInvite_list_left"]}`}>{React.$i18n('UserInvite.Invite')} 30 {React.$i18n('UserInvite.people')}</div>
              <div  className={`${styles["UserInvite_list_right"]}`}>+44 {React.$i18n('UserInvite.points')}</div>
          </div>
          <div  className={`${styles["UserInvite_list2"]}`}>
              <div  className={`${styles["UserInvite_list_left"]}`}>{React.$i18n('UserInvite.Invite')} 50 {React.$i18n('UserInvite.people')}</div>
              <div  className={`${styles["UserInvite_list_right"]}`}>+64 {React.$i18n('UserInvite.points')}</div>
          </div>
          <div  className={`${styles["UserInvite_list"]}`}>
              <div  className={`${styles["UserInvite_list_left"]}`}>{React.$i18n('UserInvite.Invite')} 100 {React.$i18n('UserInvite.people')}</div>
              <div  className={`${styles["UserInvite_list_right"]}`}>+139 {React.$i18n('UserInvite.points')} (64+50*1.5)</div>
          </div>
      </div>
      <button
              className={`${styles["UserInvBtn"]}`}
              onClick={() => {
                copyLink()
              }}
            >
            {React.$i18n('UserInvite.InviteTip')}  
        </button>
     <div  className={`${styles["UserInvline"]}`}></div>

     <div className={`${styles["UserInvite_title"]}`}>
     {React.$i18n('UserInvite.Rewardtitle')}
     </div>

     <div  className={`${styles["UserInvite_dsc"]}`}>
     {React.$i18n('UserInvite.RewardRules')}    
     </div>
     <div className={`${styles["UserInvite_box"]}`}>
          <div  className={`${styles["UserInvite_list"]}`}>
              <div  className={`${styles["UserInvite_list_left"]}`}>{React.$i18n('UserInvite.Reward')}</div>
              {/* <span>=</span> */}
              <div  className={`${styles["UserInvite_list_right"]}`}>{React.$i18n('UserInvite.purprice')} * 0.1</div>
          </div>
      </div>

      <div  className={`${styles["UserInvite_dsc"]}`}>
      {React.$i18n('UserInvite.qualifyTip')}
      </div>
     <button
              className={`${styles["UserInvBtn"]}`}
              style={{ marginTop:'20px'}}
              onClick={() => {
                message.open({
                  type: 'warning',
                  content: React.$i18n('AiwordGlobalL.Comingsoon'),
                  className: 'userTip-mess',
                  duration:3
                });
              }}
            >
           {React.$i18n('UserInvite.PURCHASE')}
        </button>
        <div  className={`${styles["UserInvline"]}`}></div>
     <div className={`${styles["UserInvite_title"]}`}>
       {React.$i18n('UserInvite.Invitation')}
     </div>
     {
     state.isphone?    <img
     className={`${styles["UserInvstep"]}`}
     src={require(`../assets/image/UserInvstep.jpg`).default}
   />:    <img
   className={`${styles["UserInvstep"]}`}
   src={require(`../assets/image/pcstep.jpg`).default}
 />
     }

    <div className={`${styles["Copylink"]}`}>
         <div className={`${styles["link"]}`}>https://aiworld.xyz/?share={props.data.address}</div>
         <button
              className={`${styles["UserInvBtn"]}`}
              style={{margin: 'auto',marginTop:'15px'}}
              onClick={() => {
                copyLink()
              }}
            >
            {React.$i18n('UserInvite.Copytext')}
        </button>
     </div>
     
     <div  className={`${styles["UserInvline"]}`}></div>
     <div className={`${styles["UserInvite_title"]}`}>
        {React.$i18n('UserInvite.PointBreakdown')}
      </div>
      <div  className={`${styles["friendsList"]}`}>
        <div className={`${styles["friendsList_header"]}`}>
              <div className={`${styles["header_list"]}`} >
                 {React.$i18n('UserInvite.WalletAddress')}
              </div> 
              <div className={`${styles["header_list"]}`}>
                 {React.$i18n('UserInvite.Date')}
              </div>
        </div>
      {items.length>0?
       <div className={`${styles["socialcontact"]}`}>
        {items.map((item) => (
             <div className={`${styles["datalist"]}`}>
                  <div className={`${styles["data_list"]}`}>    
                     <div  className={`${styles["list_address"]}`}>{item.address}</div>      
                     <div  className={`${styles["list_div"]}`}>{item.timestamp} </div>   
                  </div>
             </div>            
         ))}
        <div className={`${styles["datafooter"]}`}>
           <div className={`${styles["TotalPeople"]}`}>
             Total People：{items.length || 0}
           </div>
           <div className={`${styles["Totalpoints"]}`}>
            {/* Total Points：0 */}
           </div>
        </div>
      </div> :
        <div className={`${styles["notData"]}`}>
             <div className={`${styles["Nocontent"]}`}>{React.$i18n('UserInvite.Nottip')}</div>
             <button
              className={`${styles["UserInvBtn"]}`}
              style={{margin: 'auto',marginTop:'30px'}}
              onClick={() => {
                copyLink()
              }}
            >
           {React.$i18n('UserInvite.InviteTip')}  
        </button>
        </div>
       }

      </div>




      <div >
      <div  className={`${styles["UserInvline"]}`}></div>
      <div className={`${styles["UserInvite_title"]}`}>
        {React.$i18n('UserInvite.USDTBreakdown')} 
      </div>
      <div  className={`${styles["friendsList"]}`}>
        <div className={`${styles["friendsList_header"]}`}>
              <div className={`${styles["header_list"]}`} >
              {React.$i18n('UserInvite.WalletAddress')}
              </div>
              <div className={`${styles["header_list"]}`}>
              {React.$i18n('UserInvite.Date')}
              </div>
        </div>
        <div className={`${styles["datalist"]}`}>
             {/* <div className={`${styles["data_list"]}`}>   
                           
             </div> */}
             <div className={`${styles["notData"]}`}>
                  <div className={`${styles["Nocontent"]}`}>{React.$i18n('AiwordGlobalL.Comingsoon')}</div>
                  <button
                  className={`${styles["UserInvBtn"]}`}
                  style={{margin: 'auto',marginTop:'30px'}}
                  onClick={() => {
                    copyLink()
                  }}
                >
                {React.$i18n('UserInvite.InviteTip')}  
            </button>
            </div>
        </div>
      </div>
      </div>





  </div>
      <UsersFooter></UsersFooter>
    </div>
  );
}
export default UserInvite;
