import React, { Component, useEffect } from "react";
import { Space, Swiper } from "antd-mobile";
import * as bootstrap from "react-bootstrap";
import styles from "../assets/css/UserWallet.module.css";
import { Link, useHistory } from "react-router-dom";
import { UsersHeader,UsersFooter} from "../components";
import { CLOUD_API_URL } from "../constants";
import { Button, message } from 'antd';

function BindAccount({ data }) {
  // console.log("address in bindAccount", data.address);
  const [menuShow, setMenuShow] = React.useState(false);
  const [bindinfo, setbindinfo] = React.useState({ title: "Ioannis Korkos" });
  const [Accounttitle, setbindtitle] = React.useState({ title: "Account Number" });
  const [UserInfosohw, setUserInfosohw] = React.useState(false);

  const [inputvalue, setInputvalue] = React.useState("");
  const [index, setIndex] = React.useState("");
  useEffect(()=>{
    showUserIn() 
  },[index])

  let isphone = React.$isphone || sessionStorage.getItem("isphone")

  const handleInputChange = (event) => {
    const { value } = event.target;
    setInputvalue(value);
  };

  const history = useHistory();
  const item = [
    { image: "../assets/image/user1.jpg" },
    { image: "../assets/image/user2.jpg" },
    { image: "../assets/image/user3.jpg" },
  ];
  function back() {
    history.push({ pathname: "/UserSignIn", state: {} });
  }

  function showUserIn() {
    // console.log(index)
    if (index == 1) {
      setbindinfo({ ...bindinfo, title: React.$i18n("BindAccount.Bind") + "Twitter" });
      setbindtitle({ ...Accounttitle, title:React.$i18n("BindAccount.Your") + " Twitter " + React.$i18n("BindAccount.Account")});
    } else if (index == 2) {
      setbindinfo({ ...bindinfo, title: React.$i18n("BindAccount.Bind") + "Discord" });
      setbindtitle({  ...Accounttitle, title:React.$i18n("BindAccount.Your") + " Discord " + React.$i18n("BindAccount.Account")});
    } else if (index == 3) {
      setbindinfo({ ...bindinfo, title: React.$i18n("BindAccount.Bind") + "Email" });
      setbindtitle({  ...Accounttitle, title:React.$i18n("BindAccount.Your") + " Email " + React.$i18n("BindAccount.Account")});
    }
    if(index!=''){
      setUserInfosohw(!UserInfosohw);
    }
   
  }

  const bindAccount = async () => {
    let postData = {};
    if(!inputvalue){
      message.open({
        type: 'error',
        content: React.$i18n("BindAccount.Pleaseerr"),
        className: 'userTip-mess',
        duration:3
      });
      return;
    }
    if (index == 1) {
      postData = {
        twitter: inputvalue,
      };
    } else if (index == 2) {
      postData = {
        discord: inputvalue,
      };
    } else if (index == 3) {
      postData = {
        email: inputvalue,
      };
    }

    postData = { ...postData, address: data.address };

    try {
      const response = await fetch(`${CLOUD_API_URL}/social-media`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(postData),
      }).then(res=>{
        if(res.status== 200 ){
            setUserInfosohw(!UserInfosohw);
            setInputvalue('');
            setIndex("")
            message.open({
              type: 'success',
              content: React.$i18n("BindAccount.Binding"),
              className: 'userTip-mess',
              duration:3
            });
        }
      });
      const responseData = await response.json();
      // console.log(responseData);
      // setData(responseData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <div className={`${styles["container"]}`}>
      <UsersHeader></UsersHeader>
      <div style={{ display: isphone ? "none" : "block" }} className={`${styles["backUserSignIn"]}`} onClick={()=>{
            back()
           }}> {'<back'} </div> 
      <img
        className={`${styles["BindAccount"]}`}
        src={require(`../assets/image/BindAccount.png`).default}
      />
      <div className={`${styles["Accountbox"]}`}>
        <div
          className={`${styles["Accountbox-son"]}`}
          onClick={() => {
            setIndex(1);
          }}
        >
          <img
            className={`${styles["Bindimg"]}`}
            src={require(`../assets/image/bind1.png`).default}
          />
          <div className={`${styles["Accountext"]}`}>{React.$i18n("BindAccount.Bind")}  Twitter</div>
        </div>
        <div
          className={`${styles["Accountbox-son2"]}`}
          onClick={() => {
            setIndex(2);
          }}
        >
          <img
            className={`${styles["Bindimg"]}`}
            src={require(`../assets/image/bind2.png`).default}
          />
          <div className={`${styles["Accountext2"]}`}>{React.$i18n("BindAccount.Bind")} Discord</div>
        </div>
        <div
          className={`${styles["Accountbox-son"]}`}
          onClick={() => {
            setIndex(3);
          }}
        >
          <img
            className={`${styles["Bindimg"]}`}
            src={require(`../assets/image/bind3.png`).default}
          />
          <div className={`${styles["Accountext3"]}`}>{React.$i18n("BindAccount.Bind")} Email</div>
        </div>
      </div>

      <div
        class="UserInformation"
        style={{ display: UserInfosohw ? "block" : "none" }}
      >
        <div className={`${styles["bindbox"]}`}>
          <img
            class="closeInformation"
            onClick={() => {setUserInfosohw(!UserInfosohw);setIndex("")}}
            src={
              require("../assets/img/SketchPnge6b0562b3e1068bfcdd385f9e0568600ad5816771f1ba1ae906f13cbbc55c45b.png")
                .default
            }
          />
          <div class="UserIntest">
            <div className={`${styles["bindtitle"]}`}>{bindinfo.title}</div>
            <div class="UserInfotext">
              <div className={`${styles["AccountNumber"]}`}>{Accounttitle.title}</div>
              <input
                className={`${styles["Accountinput"]}`}
                name="twitter"
                value={inputvalue}
                onChange={handleInputChange}
                type="text"
              />
            </div>
            <button
              className={`${styles["button_1"]} flex-col align-center justify-center`}
              onClick={() => {
                bindAccount();
              }}
            >
              {bindinfo.title}
            </button>
          </div>
        </div>
      </div>

      <UsersFooter></UsersFooter>
    </div>
  );
}
export default BindAccount;
