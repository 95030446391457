import React, { Component } from "react";
import { Space, Swiper } from "antd-mobile";
import styles from "../assets/css/wallet.module.css";
import { Link,useHistory } from "react-router-dom";

function UserWallet({ yourLocalBalance, readContracts }) {
   const history = useHistory()
  function handleClick(){
    history.push({pathname: '/UserSignIn', state: { test: 'dashboard' }})
  }
  return (
       <div  className={`${styles["container"]}`}>
           <div  className={`${styles["box"]}`} onClick={handleClick}>
          <div className={`${styles["MetaMaskBox-tip"]}`}>Connect your wallet</div>
          <div  className={`${styles["MetaMaskBox-MetaMask"]}`}>
            <img
              className={`${styles["boxf2"]}`}
              src={require("../assets/image/f2.png").default}
            />
            MetaMask
          </div>
          <div  className={`${styles["MetaMaskBox-TokenPocket"]}`}>
            <img
              className={`${styles["boxf1"]}`}
              src={require("../assets/image/f1.png").default}
            />
            TokenPocket
          </div>

           </div>
       </div>
  );
}

export default UserWallet;
