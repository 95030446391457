import React, { Component, useEffect, useState } from "react";
import { Space, Swiper } from "antd-mobile";
import * as bootstrap from "react-bootstrap";
import styles from "../assets/css/UserWallet.module.css";
import { Link, useHistory } from "react-router-dom";
import { UsersHeader, UsersFooter } from "../components";
import { Button, message } from "antd";
import { Contract, utils } from "ethers";
import MINING_ABI from "../contracts/mining_contract";

function Mining(props) {
  // console.log(props);

  const contract = new Contract(
    // props.data.contractAddress,
    "0x04919f277cfFB234CE2660769404FbFcC3673d85", // bsc mainnet mining
    MINING_ABI,
    props.data.userSigner
  );

  // async function getUserNodes(userAddress) {
  //   console.log(`address is ${userAddress}`);
  //   const nodeCount = await contract.getNodeDetailsLength(userAddress);
  //   const nodes = [];
  //   for (let i = 0; i < nodeCount; i++) {
  //     const node = await contract.getNodeDetails(userAddress, i);
  //     nodes.push(node);
  //   }
  //   console.log(nodes);
  //   return nodes;
  // }

  async function getMiningTokens(userAddress) {
    const miningTokens = await contract.getMiningTokens(userAddress);

    return utils.formatEther(miningTokens);
  }

  async function mining() {
    try {
      const tx = await contract.mining();
      console.log(tx);
      setMiningTokens(0);
    } catch (err) {
      console.log(err);
    }
  }

  const [miningTokens, setMiningTokens] = useState([]);

  React.useEffect(async () => {
    const miningTokens = await getMiningTokens(props.data.address);
    console.log("miningTokens: ", miningTokens);

    setMiningTokens(miningTokens);
    // getnodes()
  }, [props.data.mainnetProvider, props.data.address]);

  const state = {
    isphone: false,
    MetaMaskshow: false,
  };
  function browserRedirect() {
    var sUserAgent = navigator.userAgent.toLowerCase();
    var bIsIpad = sUserAgent.match(/ipad/i) == "ipad";
    var bIsIphoneOs = sUserAgent.match(/iphone os/i) == "iphone os";
    var bIsMidp = sUserAgent.match(/midp/i) == "midp";
    var bIsUc7 = sUserAgent.match(/rv:1.2.3.4/i) == "rv:1.2.3.4";
    var bIsUc = sUserAgent.match(/ucweb/i) == "ucweb";
    var bIsAndroid = sUserAgent.match(/android/i) == "android";
    var bIsCE = sUserAgent.match(/windows ce/i) == "windows ce";
    var bIsWM = sUserAgent.match(/windows mobile/i) == "windows mobile";
    if (
      bIsIpad ||
      bIsIphoneOs ||
      bIsMidp ||
      bIsUc7 ||
      bIsUc ||
      bIsAndroid ||
      bIsCE ||
      bIsWM
    ) {
      //跳转移动端页面
      state.isphone = true;
    } else {
      //跳转pc端页面
      state.isphone = false;
    }
  }
  browserRedirect();

  const history = useHistory();
  function back() {
    history.push({ pathname: "/UserSignIn", state: {} });
  }
  function copyLink() {
    const createInput = document.createElement("input");
    createInput.value = `https://aiworld.xyz/?share=${props.data.address}`;
    document.body.appendChild(createInput);
    createInput.select();
    document.execCommand("Copy"); // document执行复制操作
    createInput.remove();
    message.open({
      type: "success",
      content: "Successfully copied invitation link",
      className: "userTip-mess",
      duration: 3,
    });
  }

  return (
    <div className={`${styles["container"]}`}>
      <UsersHeader></UsersHeader>
      <div
        style={{ display: state.isphone ? "none" : "block" }}
        className={`${styles["backUserSignIn"]} `}
        onClick={() => {
          back();
        }}
      >
        {" "}
        {"<  back"}{" "}
      </div>

      <div className={`${styles["miningbody"]}`}>
        <div className="align-center">
          {React.$i18n("Mining.Desc")}: {miningTokens.toString()}
        </div>
        <button
          className={`${styles["button_mining"]} flex-col align-center justify-center`}
          onClick={mining}
        >
          {React.$i18n("Mining.Mining")}
        </button>
      </div>
      <UsersFooter></UsersFooter>
    </div>
  );
}
export default Mining;
