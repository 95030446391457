/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from "react";
import styles from "../assets/index.module.css";
import "../assets/index.css";
import { Account } from "../components";
import { Link, useHistory } from "react-router-dom";
import { Button, message, Dropdown, Menu, Space, Typography } from "antd";
import { DownOutlined, SmileOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
export default function Userheader() {
  const [menuShow, setMenuShow] = React.useState(false);
  const [MetaMaskshow, setMetaMaskshow] = React.useState(false);
  const [artists, setArtists] = React.useState([]);
  const history = useHistory();
  const [language, setlanguage] = React.useState("English");
  const { t, i18n } = useTranslation();

  // const [props, setprops] = React.useState({});
  let props = {};
  if (React.$userinfo != "") {
    props.data = React.$userinfo;
  }

  const items = [
    {
      key: "1",
      label: React.$i18n("AiwordGlobalL.English"),
    },
    {
      key: "2",
      label: React.$i18n("AiwordGlobalL.Chinese"),
    },
  ];

  const state = {
    isphone: false,
    MetaMaskshow: false,
  };
  function browserRedirect() {
    var sUserAgent = navigator.userAgent.toLowerCase();
    var bIsIpad = sUserAgent.match(/ipad/i) == "ipad";
    var bIsIphoneOs = sUserAgent.match(/iphone os/i) == "iphone os";
    var bIsMidp = sUserAgent.match(/midp/i) == "midp";
    var bIsUc7 = sUserAgent.match(/rv:1.2.3.4/i) == "rv:1.2.3.4";
    var bIsUc = sUserAgent.match(/ucweb/i) == "ucweb";
    var bIsAndroid = sUserAgent.match(/android/i) == "android";
    var bIsCE = sUserAgent.match(/windows ce/i) == "windows ce";
    var bIsWM = sUserAgent.match(/windows mobile/i) == "windows mobile";
    if (
      bIsIpad ||
      bIsIphoneOs ||
      bIsMidp ||
      bIsUc7 ||
      bIsUc ||
      bIsAndroid ||
      bIsCE ||
      bIsWM
    ) {
      //跳转移动端页面
      state.isphone = true;
    } else {
      //跳转pc端页面
      state.isphone = false;
    }
  }
  browserRedirect();

  function backhome() {
    history.push({ pathname: "/", state: {} });
  }

  React.useEffect(() => {
    let browserLang = navigator.language
      ? navigator.language
      : navigator.browserLanguage;
    let defaultlanguage = "";
    if (localStorage.getItem("language")) {
      defaultlanguage = localStorage.getItem("language");
      if (localStorage.getItem("language") == "zh") {
        setlanguage("中文");
      } else {
        setlanguage("English");
      }
    } else {
      if (
        browserLang.toLowerCase() === "cn" ||
        browserLang.toLowerCase() === "zh" ||
        browserLang.toLowerCase() === "zh-cn"
      ) {
        setlanguage("中文");
      } else {
        setlanguage("English");
      }
    }

    function handleResize() {
      if (window.innerWidth < 500) {
        state.isphone = true;
      } else {
        state.isphone = false;
      }
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleMenuClick = (e) => {
    console.log(e.key);
    if (e.key == "1") {
      i18n.changeLanguage("en");
      setlanguage("English");
      localStorage.setItem("language", "en");
    } else {
      i18n.changeLanguage("zh");
      setlanguage("中文");
      localStorage.setItem("language", "zh");
    }
  };

  return (
    // ${styles["nav-bar_1"]}
    <div className={"container-fluid"}>
      {/* pc端 */}
      <nav
        id="userheader"
        class="navbar navbar-expand-lg bg-body-tertiary"
        style={{ display: state.isphone ? "none" : "flex" }}
      >
        <a class="navbar-brand" href="#">
          <img src={require("../assets/image/logo.png").default} />
        </a>
        <div id="navbarText">
          <div
            class="navbar-HOME"
            style={{ color: React.$currentpage == "home" ? "#00F56E" : "" }}
            onClick={() => {
              React.$currentpage = "home";
              setMenuShow(false);
              history.push({ pathname: "/", state: {} });
            }}
          >
            {React.$i18n("AiwordGlobalL.HOME")}
          </div>

          <div
            className={`${styles["INTEGRAL"]}`}
            style={{ color: React.$currentpage == "NODES" ? "#00F56E" : "" }}
            onClick={() => {
              if (
                props.data.address &&
                props.data.userSigner &&
                props.data.web3Modal?.cachedProvider
              ) {
                React.$currentpage = "NODES";
                setMenuShow(false);
                history.push({ pathname: "/Nodes", state: {} });
              } else {
                message.open({
                  type: "error",
                  content: React.$i18n("AiwordGlobalL.loginTip"),
                  className: "userTip-mess",
                  duration: 3,
                });
              }
            }}
          >
            {React.$i18n("AiwordGlobalL.NODES")}
          </div>
          <div
            className={`${styles["INTEGRAL"]}`}
            style={{ color: React.$currentpage == "MyNode" ? "#00F56E" : "" }}
            onClick={() => {
              if (
                props.data.address &&
                props.data.userSigner &&
                props.data.web3Modal?.cachedProvider
              ) {
                React.$currentpage = "MyNode";
                setMenuShow(false);
                history.push({ pathname: "/MyNodes", state: {} });
              } else {
                message.open({
                  type: "error",
                  content: React.$i18n("AiwordGlobalL.loginTip"),
                  className: "userTip-mess",
                  duration: 3,
                });
              }
            }}
          >
            {React.$i18n("AiwordGlobalL.MyNode")}
          </div>

          <div
            className={`${styles["INTEGRAL"]}`}
            style={{ color: React.$currentpage == "REWARDS" ? "#00F56E" : "" }}
            onClick={() => {
              if (
                props.data.address &&
                props.data.userSigner &&
                props.data.web3Modal?.cachedProvider
              ) {
                React.$currentpage = "REWARDS";
                setMenuShow(false);
                history.push({ pathname: "/UserSignIn", state: {} });
              } else {
                message.open({
                  type: "error",
                  content: React.$i18n("AiwordGlobalL.loginTip"),
                  className: "userTip-mess",
                  duration: 3,
                });
              }
            }}
          >
            {React.$i18n("AiwordGlobalL.REWARDS")}
          </div>

          {/* <div
          className={`${styles["INTEGRAL"]}`}
          style={{color:( React.$currentpage == "Publicoffering")?'#00F56E':''}}
          onClick={() => {
            if(props.data.address && props.data.userSigner && props.data.web3Modal?.cachedProvider){
              React.$currentpage = "Publicoffering"
               setMenuShow(false)
              history.push({ pathname: "/Publicoffering", state: {} });
            }else{
              message.open({
                type: 'error',
                content: React.$i18n('AiwordGlobalL.loginTip'),
                className: 'userTip-mess',
                duration:3
              });
            }
            
          }}
        >
           {React.$i18n('AiwordGlobalL.PUBLICOFFER')}
        </div>    */}

          <div
            style={{ color: React.$currentpage == "CHATBOT" ? "#00F56E" : "" }}
            target="_blank"
            className={`${styles["INTEGRAL"]}`}
            onClick={() => {
              React.$currentpage = "CHATBOT";
              setMenuShow(false);
              history.push({ pathname: "/Chat", state: {} });
            }}
          >
            {React.$i18n("AiwordGlobalL.CHATBOT")}
          </div>

          {/* <div  style={{color:( React.$currentpage == "ROADMAP")?'#00F56E':''}}  target="_blank" className={`${styles["INTEGRAL"]}`}  onClick={() => {
             React.$currentpage = "ROADMAP"
             setMenuShow(false)
             history.push({ pathname: "/", state:"true"});
             setTimeout(() => {
              const target = document.querySelector('#footer');
              if(target){
                 const targetPosition = target.getBoundingClientRect().top + window.pageYOffset;
                 window.scrollTo({
                   top: targetPosition,
                   behavior: 'smooth'
                 });
              }
             }, 500);

          }}>
          {React.$i18n('AiwordGlobalL.ROADMAP')}
     </div> */}

          <div
            style={{ color: React.$currentpage == "MINING" ? "#00F56E" : "" }}
            target="_blank"
            className={`${styles["INTEGRAL"]}`}
            onClick={() => {
              React.$currentpage = "MINING";
              setMenuShow(false);
              history.push({ pathname: "/Mining", state: {} });
              setTimeout(() => {
                const target = document.querySelector("#footer");
                if (target) {
                  const targetPosition =
                    target.getBoundingClientRect().top + window.pageYOffset;
                  window.scrollTo({
                    top: targetPosition,
                    behavior: "smooth",
                  });
                }
              }, 500);
            }}
          >
            {React.$i18n("AiwordGlobalL.MINING")}
          </div>

          <div
            style={{
              color: React.$currentpage == "PARTNERSHIP" ? "#00F56E" : "",
            }}
            target="_blank"
            className={`${styles["PARTNERSHIP"]}`}
            onClick={() => {
              React.$currentpage = "PARTNERSHIP";
              setMenuShow(false);
              window.open("https://alt.jotfor.ms/231062650799461", "_blank");
            }}
          >
            {React.$i18n("AiwordGlobalL.PARTNERSHIP")}
          </div>
        </div>

        <div className={`${styles["language"]}`}>
          <Dropdown
            menu={{
              items,
              selectable: true,
              defaultSelectedKeys: ["2"],
              onClick: handleMenuClick,
            }}
          >
            <Typography.Link onClick={(e) => e.preventDefault()}>
              <Space style={{ color: "#ffffff" }}>
                {React.$i18n("UsersHeader.language")}: {language}
              </Space>
            </Typography.Link>
          </Dropdown>
        </div>

        <div
          class="navbar-CONNECT"
          // onClick={() => setMetaMaskshow(!MetaMaskshow)}
        >
          <div style={{ display: "flex", flex: 1 }}>
            <Account
              useBurner={props.data.USE_BURNER_WALLET}
              address={props.data.address}
              localProvider={props.data.localProvider}
              userSigner={props.data.userSigner}
              mainnetProvider={props.data.mainnetProvider}
              web3Modal={props.data.web3Modal}
              loadWeb3Modal={props.data.loadWeb3Modal}
              logoutOfWeb3Modal={props.data.logoutOfWeb3Modal}
              blockExplorer={props.data.blockExplorer}
            />
          </div>
        </div>

        <div
          className="MetaMaskBox"
          style={{ display: MetaMaskshow ? "block" : "none" }}
        >
          <div className="MetaMaskBox-tip">
            {React.$i18n("UsersHeader.ConnecTip")}
          </div>
          <div className="MetaMaskBox-MetaMask">
            <img
              className={`${styles["boxf2"]}`}
              src={require("../assets/image/f2.png").default}
            />
            MetaMask
          </div>
          <div className="MetaMaskBox-TokenPocket">
            <img
              className={`${styles["boxf1"]}`}
              src={require("../assets/image/f1.png").default}
            />
            {React.$i18n("UsersHeader.TokenPocket")}
          </div>
        </div>
      </nav>

      <div
        className={`${styles["nav-bar"]} flex-row justify-between`}
        style={{ display: state.isphone ? "flex" : "none" }}
      >
        <div className={`${styles["image-wrapper_1"]} flex-row`}>
          <img
            className={`${styles["image_1"]}`}
            src={require("../assets/image/logo.png").default}
            onClick={() => {
              backhome();
            }}
          />
        </div>
        <img
          className={`${styles["label_1"]}`}
          onClick={() => setMenuShow(!menuShow)}
          src={
            require(menuShow
              ? "../assets/img/SketchPnge6b0562b3e1068bfcdd385f9e0568600ad5816771f1ba1ae906f13cbbc55c45b.png"
              : "../assets/img/SketchPngd673244330b372d8a8b40fa46a730e2bcf4c1090c3b8e2f6373be9d474f3bb0b.png")
              .default
          }
        />
      </div>

      <div
        className={`${styles["box_999"]} flex-col align-center`}
        style={{ display: menuShow ? "flex" : "none" }}
      >
        <span
          className={`${styles["HOME"]}`}
          style={{ color: React.$currentpage == "home" ? "#00F56E" : "" }}
          onClick={() => {
            React.$currentpage = "home";
            setMenuShow(false);
            history.push({ pathname: "/", state: {} });
          }}
        >
          {React.$i18n("AiwordGlobalL.HOME")}
        </span>
        {/* <span className={`${styles["text_201"]}`}>NODES</span> */}

        <div
          className={`${styles["INTEGRAL"]}`}
          style={{ color: React.$currentpage == "NODES" ? "#00F56E" : "" }}
          onClick={() => {
            if (
              props.data.address &&
              props.data.userSigner &&
              props.data.web3Modal?.cachedProvider
            ) {
              React.$currentpage = "NODES";
              setMenuShow(false);
              history.push({ pathname: "/Nodes", state: {} });
            } else {
              message.open({
                type: "error",
                content: React.$i18n("AiwordGlobalL.loginTip"),
                className: "userTip-mess",
                duration: 3,
              });
            }
          }}
        >
          {React.$i18n("AiwordGlobalL.NODES")}
        </div>

        <div
          className={`${styles["INTEGRAL"]}`}
          style={{ color: React.$currentpage == "MyNode" ? "#00F56E" : "" }}
          onClick={() => {
            if (
              props.data.address &&
              props.data.userSigner &&
              props.data.web3Modal?.cachedProvider
            ) {
              React.$currentpage = "MyNode";
              setMenuShow(false);
              history.push({ pathname: "/MyNodes", state: {} });
            } else {
              message.open({
                type: "error",
                content: React.$i18n("AiwordGlobalL.loginTip"),
                className: "userTip-mess",
                duration: 3,
              });
            }
          }}
        >
          {React.$i18n("AiwordGlobalL.MyNode")}
        </div>

        <span
          className={`${styles["INTEGRAL"]}`}
          style={{ color: React.$currentpage == "REWARDS" ? "#00F56E" : "" }}
          onClick={() => {
            if (
              props.data.address &&
              props.data.userSigner &&
              props.data.web3Modal?.cachedProvider
            ) {
              React.$currentpage = "REWARDS";
              setMenuShow(false);
              history.push({ pathname: "/UserSignIn", state: {} });
            } else {
              message.open({
                type: "error",
                content: React.$i18n("AiwordGlobalL.loginTip"),
                className: "userTip-mess",
                duration: 3,
              });
            }
          }}
        >
          {React.$i18n("AiwordGlobalL.REWARDS")}
        </span>

        {/* <div  className={`${styles["INTEGRAL"]}`}
          style={{color:( React.$currentpage == "Publicoffering")?'#00F56E':''}}
          onClick={() => {
            if(props.data.address && props.data.userSigner && props.data.web3Modal?.cachedProvider){
              React.$currentpage = "Publicoffering"
               setMenuShow(false)
              history.push({ pathname: "/Publicoffering", state: {} });
            }else{
              message.open({
                type: 'error',
                content: React.$i18n('AiwordGlobalL.loginTip'),
                className: 'userTip-mess',
                duration:3
              });
            }
            
          }}
        >
          {React.$i18n('AiwordGlobalL.PUBLICOFFER')}
        </div> */}

        <div
          style={{ color: React.$currentpage == "CHATBOT" ? "#00F56E" : "" }}
          target="_blank"
          className={`${styles["INTEGRAL"]}`}
          onClick={() => {
            React.$currentpage = "CHATBOT";
            setMenuShow(false);
            history.push({ pathname: "/Chat", state: {} });
          }}
        >
          {React.$i18n("AiwordGlobalL.CHATBOT")}
        </div>

        <div
          style={{ color: React.$currentpage == "ROADMAP" ? "#00F56E" : "" }}
          target="_blank"
          className={`${styles["INTEGRAL"]}`}
          onClick={() => {
            React.$currentpage = "ROADMAP";
            setMenuShow(false);
            history.push({ pathname: "/", state: "true" });
            setTimeout(() => {
              const target = document.querySelector("#footer");
              if (target) {
                const targetPosition =
                  target.getBoundingClientRect().top + window.pageYOffset;
                window.scrollTo({
                  top: targetPosition,
                  behavior: "smooth",
                });
              }
            }, 500);
          }}
        >
          {React.$i18n("AiwordGlobalL.ROADMAP")}
        </div>

        <div
          style={{ color: React.$currentpage == "MINING" ? "#00F56E" : "" }}
          target="_blank"
          className={`${styles["INTEGRAL"]}`}
          onClick={() => {
            React.$currentpage = "MINING";
            setMenuShow(false);
            history.push({ pathname: "/Mining", state: "true" });
            setTimeout(() => {
              const target = document.querySelector("#footer");
              if (target) {
                const targetPosition =
                  target.getBoundingClientRect().top + window.pageYOffset;
                window.scrollTo({
                  top: targetPosition,
                  behavior: "smooth",
                });
              }
            }, 500);
          }}
        >
          {React.$i18n("AiwordGlobalL.MINING")}
        </div>

        <div
          style={{
            color: React.$currentpage == "PARTNERSHIP" ? "#00F56E" : "",
          }}
          target="_blank"
          className={`${styles["PARTNERSHIP"]}`}
          onClick={() => {
            React.$currentpage = "PARTNERSHIP";
            setMenuShow(false);
            window.open("https://alt.jotfor.ms/231062650799461", "_blank");
          }}
        >
          {React.$i18n("AiwordGlobalL.PARTNERSHIP")}
        </div>

        <div className={`${styles["language"]}`}>
          <Dropdown
            menu={{
              items,
              selectable: true,
              defaultSelectedKeys: ["2"],
              onClick: handleMenuClick,
            }}
          >
            <Typography.Link onClick={(e) => e.preventDefault()}>
              <Space style={{ color: "#ffffff" }}>
                {React.$i18n("UsersHeader.language")}: {language}
              </Space>
            </Typography.Link>
          </Dropdown>
        </div>

        <button
          className={`${styles["CONNECT"]} flex-col align-center justify-center`}
        >
          <Account
            useBurner={props.data.USE_BURNER_WALLET}
            address={props.data.address}
            localProvider={props.data.localProvider}
            userSigner={props.data.userSigner}
            mainnetProvider={props.data.mainnetProvider}
            web3Modal={props.data.web3Modal}
            loadWeb3Modal={props.data.loadWeb3Modal}
            logoutOfWeb3Modal={props.data.logoutOfWeb3Modal}
            blockExplorer={props.data.blockExplorer}
          />
        </button>
        <div className={`image-wrapper_999 flex-row`}>
          <a href="https://t.me/AIWORLD0765" target="_blank" rel="noreferrer">
            <img
              className={`${styles["icon_1-0"]}`}
              src={require("../assets/image/sj1.png").default}
            />
          </a>
          <a
            href="https://medium.com/@aiworld0765"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className={`${styles["icon_1-1"]}`}
              src={require("../assets/image/sj2.png").default}
            />
          </a>
          <a
            href="https://www.youtube.com/@AIWORLD-0765/videos"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className={`${styles["icon_1-2"]}`}
              src={require("../assets/image/ytb.png").default}
            />
          </a>
          <a
            href="https://twitter.com/AIWORLD_HQ"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className={`${styles["icon_1-3"]}`}
              src={require("../assets/image/sj4.png").default}
            />
          </a>
        </div>
      </div>
    </div>
  );
}
