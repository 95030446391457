import { Button, Col, Menu, Row, message } from "antd";
import "antd/dist/antd.css";
import {
  useBalance,
  useContractLoader,
  useContractReader,
  // useOnBlock,
  useUserProviderAndSigner,
} from "eth-hooks";
import { useExchangeEthPrice } from "eth-hooks/dapps/dex";
import React, { useCallback, useEffect, useState } from "react";
import { Link, Route, Switch, useLocation } from "react-router-dom";
import "./App.css";
import { useTranslation } from "react-i18next";
import {
  Account,
  Contract,
  Faucet,
  GasGauge,
  Header,
  Ramp,
  ThemeSwitch,
  NetworkDisplay,
  FaucetHint,
  NetworkSwitch,
  UsersHeader,
  UsersFooter,
} from "./components";
import { NETWORKS, ALCHEMY_KEY } from "./constants";
import externalContracts from "./contracts/external_contracts";
import { useHistory } from "react-router-dom";
// contracts
import deployedContracts from "./contracts/hardhat_contracts.json";
import { getRPCPollTime, Transactor, Web3ModalSetup } from "./helpers";
import axios from "axios";
import {
  Home,
  ExampleUI,
  Hints,
  Subgraph,
  UserWallet,
  UserSignIn,
  UserCalendars,
  BindAccount,
  UserFollow,
  UserInvite,
  Mynodes,
  Nodes,
  BuyNodes,
  Chat,
  Points,
  Publicoffering,
  Mining,
} from "./views";
import APP2 from "./APP2";
import { useStaticJsonRPC, useGasPrice } from "./hooks";
const { ethers } = require("ethers");
/*
    Welcome to 🏗 scaffold-eth !

    Code:
    https://github.com/scaffold-eth/scaffold-eth

    Support:
    https://t.me/joinchat/KByvmRe5wkR-8F_zz6AjpA
    or DM @austingriffith on twitter or telegram

    You should get your own Alchemy.com & Infura.io ID and put it in `constants.js`
    (this is your connection to the main Ethereum network for ENS etc.)


    🌏 EXTERNAL CONTRACTS:
    You can also bring in contract artifacts in `constants.js`
    (and then use the `useExternalContractLoader()` hook!)
*/

/// 📡 What chain are your contracts deployed to?
const initialNetwork = NETWORKS.localhost; // <------- select your target frontend network (localhost, goerli, xdai, mainnet)

// 😬 Sorry for all the console logging
const DEBUG = true;
const NETWORKCHECK = true;
const USE_BURNER_WALLET = true; // toggle burner wallet feature
const USE_NETWORK_SELECTOR = false;

const web3Modal = Web3ModalSetup();

// 🛰 providers
const providers = [
  "https://bsc-dataseed.binance.org/",
  "https://eth-sepolia.g.alchemy.com/v2/fwqWL6kux-CxhkhSIAl2h2ly9VApZk5I",
  // "https://eth-mainnet.gateway.pokt.network/v1/lb/611156b4a585a20035148406",
  // `https://eth-mainnet.alchemyapi.io/v2/${ALCHEMY_KEY}`,
  // "https://rpc.scaffoldeth.io:48544",
];
// const providers = [
//   "https://bsc-dataseed1.binance.org/"
// ]

function App(props) {
  // specify all the chains your app is available on. Eg: ['localhost', 'mainnet', ...otherNetworks ]
  // reference './constants.js' for other networks
  const networkOptions = ["BSC", "sepolia"];

  const [injectedProvider, setInjectedProvider] = useState();
  const [address, setAddress] = useState();
  const [selectedNetwork, setSelectedNetwork] = useState(networkOptions[0]);
  const location = useLocation();

  const targetNetwork = NETWORKS[selectedNetwork];

  // 🔭 block explorer URL
  const blockExplorer = targetNetwork.blockExplorer;

  // load all your providers
  const localProvider = useStaticJsonRPC([
    process.env.REACT_APP_PROVIDER
      ? process.env.REACT_APP_PROVIDER
      : targetNetwork.rpcUrl,
  ]);

  const mainnetProvider = useStaticJsonRPC(providers, localProvider);

  // Sensible pollTimes depending on the provider you are using
  const localProviderPollingTime = getRPCPollTime(localProvider);
  const mainnetProviderPollingTime = getRPCPollTime(mainnetProvider);
  let history = useHistory();
  if (DEBUG) console.log(`Using ${selectedNetwork} network`);
  // 🛰 providers
  // if (DEBUG) console.log("📡 Connecting to Mainnet Ethereum");

  const logoutOfWeb3Modal = async () => {
    await web3Modal.clearCachedProvider();
    if (
      injectedProvider &&
      injectedProvider.provider &&
      typeof injectedProvider.provider.disconnect == "function"
    ) {
      await injectedProvider.provider.disconnect();
    }
    setTimeout(() => {
      sessionStorage.clear();
      React.$userinfo = "";
      localStorage.removeItem("HistoricalChat");
      localStorage.removeItem("Chatnumber");
      localStorage.removeItem("lastClearDate");
      localStorage.removeItem("firstcheckin");
      localStorage.removeItem("lastDate");
      history.push({ pathname: "/", state: {} });
      window.location.reload();
    }, 1);
  };

  /* 💵 This hook will get the price of ETH from 🦄 Uniswap: */
  // const price = useExchangeEthPrice(
  //   targetNetwork,
  //   mainnetProvider,
  //   mainnetProviderPollingTime
  // );

  /* 🔥 This hook will get the price of Gas from ⛽️ EtherGasStation */
  // const gasPrice = useGasPrice(
  //   targetNetwork,
  //   "FastGasPrice",
  //   localProviderPollingTime
  // );
  // Use your injected provider from 🦊 Metamask or if you don't have it then instantly generate a 🔥 burner wallet.
  const userProviderAndSigner = useUserProviderAndSigner(
    injectedProvider,
    localProvider,
    USE_BURNER_WALLET
  );

  const userSigner = userProviderAndSigner.signer;
  useEffect(() => {
    async function getAddress() {
      if (userSigner) {
        const newAddress = await userSigner.getAddress();
        setAddress(newAddress);
        //  新用户签到
        //  let firstcheckin = localStorage.getItem('firstcheckin') || 0
        //  if(localStorage.getItem('firstcheckin')!=1){
        //   const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        //   const headers = new Headers();
        //   // console.log("我登录了-------------------------",newAddress)
        //   headers.append("Content-Type", "application/json");
        //   fetch(
        //     "https://api.aiworld.xyz/checkin",
        //     {
        //       method: "POST",
        //       headers,
        //       body: JSON.stringify({
        //         address: newAddress,
        //         timezone:timeZone,
        //       }),
        //     }
        //   ).then((res)=>{
        //       localStorage.setItem("firstcheckin", 1);
        //   })
        // }

        let url = window.location.href; //获取url中"?"符后的字串
        if (url.indexOf("?share=") != -1) {
          //判断是否有参数
          let str = url.split("=");
          str = str[1]; //用等号进行分隔 （因为知道只有一个参数 所以直接用等号进分隔 如果有多个参数 要用&号分隔 再用等号进行分隔）
          if (str.indexOf("#") != -1) {
            str = str.split("#")[0];
          }
          if (str.indexOf("&") != -1) {
            str = str.split("&")[0];
          }
          axios
            .post(`https://api.aiworld.xyz/ref`, {
              address: newAddress,
              refAddress: str,
            })
            .then((res) => {
              //  console.log("66666666666666666666666666666666",res)
            })
            .catch((err) => {
              if (err.response) {
                if (err.response.status == 500) {
                  //  创建失败
                  console.log("创建失败");
                }
              }
            });
        }

        axios.get(`https://api.aiworld.xyz/users/${newAddress}`).then((res) => {
          //  console.log("66666666666666666666666666666666",res,  React.$userinfo )
          if (res.data.cnt) {
            React.$cnt = res.data.cnt;
          } else {
            React.$cnt = 0;
          }
          React.$refAddress = res.data.refAddress || "";
          React.$uid = res.data.uid || "";
        });
      }
    }
    getAddress();
  }, [userSigner]);

  // You can warn the user if you would like them to be on a specific network
  const localChainId =
    localProvider && localProvider._network && localProvider._network.chainId;
  const selectedChainId =
    userSigner &&
    userSigner.provider &&
    userSigner.provider._network &&
    userSigner.provider._network.chainId;
  console.log(
    `local chain id is ${localChainId} and selectedChainid is ${selectedChainId}`
  );
  React.$localChainId = selectedChainId;
  React.useEffect(() => {
    if (
      React.$localChainId != 56 &&
      React.$localChainId != null &&
      React.$localChainId != "" &&
      React.$localChainId != undefined
    ) {
      message.open({
        type: "warning",
        content: React.$i18n("AiwordGlobalL.BSCnetwork"),
        className: "userTip-mess",
        duration: 4,
      });
    }
  }, [selectedChainId]);

  // For more hooks, check out 🔗eth-hooks at: https://www.npmjs.com/package/eth-hooks

  // The transactor wraps transactions and provides notificiations
  const tx = Transactor(userSigner, 0);

  // 🏗 scaffold-eth is full of handy hooks like this one to get your balance:
  const yourLocalBalance = useBalance(
    localProvider,
    address,
    localProviderPollingTime
  );

  // Just plug in different 🛰 providers to get your balance on different chains:
  const yourMainnetBalance = useBalance(
    mainnetProvider,
    address,
    mainnetProviderPollingTime
  );

  // const contractConfig = useContractConfig();

  const contractConfig = {
    deployedContracts: deployedContracts || {},
    externalContracts: externalContracts || {},
  };

  // Load in your local 📝 contract and read a value from it:
  const readContracts = useContractLoader(localProvider, contractConfig);

  // If you want to make 🔐 write transactions to your contracts, use the userSigner:
  const writeContracts = useContractLoader(
    userSigner,
    contractConfig,
    localChainId
  );

  // EXTERNAL CONTRACT EXAMPLE:
  //
  // If you want to bring in the mainnet DAI contract it would look like:
  const mainnetContracts = useContractLoader(mainnetProvider, contractConfig);

  // If you want to call a function on a new block
  // useOnBlock(mainnetProvider, () => {
  //   console.log(`⛓ A new mainnet block is here: ${mainnetProvider._lastBlockNumber}`);
  // });

  // Then read your DAI balance like:
  const myMainnetDAIBalance = useContractReader(
    mainnetContracts,
    "DAI",
    "balanceOf",
    ["0x34aA3F359A9D614239015126635CE7732c18fDF3"],
    mainnetProviderPollingTime
  );

  // keep track of a variable from the contract in the local React state:
  const purpose = useContractReader(
    readContracts,
    "YourContract",
    "purpose",
    [],
    localProviderPollingTime
  );

  /*
  const addressFromENS = useResolveName(mainnetProvider, "austingriffith.eth");
  console.log("🏷 Resolved austingriffith.eth as:", addressFromENS)
  */

  //
  // 🧫 DEBUG 👨🏻‍🔬
  //
  useEffect(() => {
    if (
      DEBUG &&
      mainnetProvider &&
      address &&
      selectedChainId &&
      yourLocalBalance &&
      yourMainnetBalance &&
      readContracts &&
      writeContracts &&
      mainnetContracts
    ) {
      console.log(
        "_____________________________________ 🏗 scaffold-eth _____________________________________"
      );
      // console.log("🌎 mainnetProvider", mainnetProvider);
      // console.log("🏠 localChainId", localChainId);
      // console.log("👩‍💼 selected address:", address);
      // console.log("🕵🏻‍♂️ selectedChainId:", selectedChainId);
      console.log(
        "💵 yourLocalBalance",
        yourLocalBalance ? ethers.utils.formatEther(yourLocalBalance) : "..."
      );
      console.log(
        "💵 yourMainnetBalance",
        yourMainnetBalance
          ? ethers.utils.formatEther(yourMainnetBalance)
          : "..."
      );
      // console.log("📝 readContracts", readContracts);
      // console.log("🌍 DAI contract on mainnet:", mainnetContracts);
      // console.log("💵 yourMainnetDAIBalance", myMainnetDAIBalance);
      // console.log("🔐 writeContracts", writeContracts);
    }
  }, [
    mainnetProvider,
    address,
    selectedChainId,
    yourLocalBalance,
    yourMainnetBalance,
    readContracts,
    writeContracts,
    mainnetContracts,
    localChainId,
    myMainnetDAIBalance,
  ]);

  const loadWeb3Modal = useCallback(async () => {
    //const provider = await web3Modal.connect();
    const provider = await web3Modal.requestProvider();
    setInjectedProvider(new ethers.providers.Web3Provider(provider));

    provider.on("chainChanged", (chainId) => {
      setInjectedProvider(new ethers.providers.Web3Provider(provider));
    });

    provider.on("accountsChanged", () => {
      // 切换账号
      React.$userinfo = "";
      localStorage.removeItem("Chatnumber");
      localStorage.removeItem("lastDate");
      localStorage.removeItem("lastClearDate");
      localStorage.removeItem("HistoricalChat");
      setInjectedProvider(new ethers.providers.Web3Provider(provider));
    });

    // Subscribe to session disconnection
    provider.on("disconnect", (code, reason) => {
      console.log(code, reason);
      logoutOfWeb3Modal();
    });

    // Call getChatList only once when the component mount
    // eslint-disable-next-line
  }, [setInjectedProvider]);

  useEffect(() => {
    if (web3Modal.cachedProvider) {
      loadWeb3Modal();
    }
    //automatically connect if it is a safe app
    const checkSafeApp = async () => {
      if (await web3Modal.isSafeApp()) {
        loadWeb3Modal();
      }
    };
    checkSafeApp();
  }, [loadWeb3Modal]);

  const faucetAvailable =
    localProvider &&
    localProvider.connection &&
    targetNetwork.name.indexOf("local") !== -1;

  // let contractAddress = "0xc59704105F7E62214C94ba7E58E172a0609eeF95";
  // let usdtAddress = "0x542269f43DdeC3177cFedb61590E2eC2CaCb6397";

  // below comments is for bsc mainnet
  let contractAddress = "0xe37372f113A7c224DA917103f0D6B4A49ABAb7D9";
  let usdtAddress = "0x55d398326f99059fF775485246999027B3197955";

  const data = {
    contractAddress,
    usdtAddress,
    USE_BURNER_WALLET,
    address,
    localProvider,
    userSigner,
    mainnetProvider,
    web3Modal,
    loadWeb3Modal,
    logoutOfWeb3Modal,
    blockExplorer,
    tx,
  };

  // console.log("Account---------------",data);
  // console.log("isContract----------------",web3Modal?.cachedProvider)
  // 国际化
  const { t, i18n } = useTranslation();
  React.$i18n = t;
  React.$userinfo = data;
  return (
    <div className="App">
      {/* ✏️ Edit the header and change the title to your project name */}
      {/* <Header> */}
      {/* 👨‍💼 Your account is in the top right with a wallet at connect options */}
      {/* <div style={{ position: "relative", display: "flex", flexDirection: "column" }}>
          <div style={{ display: "flex", flex: 1 }}>
            {USE_NETWORK_SELECTOR && (
              <div style={{ marginRight: 20 }}>
                <NetworkSwitch
                  networkOptions={networkOptions}
                  selectedNetwork={selectedNetwork}
                  setSelectedNetwork={setSelectedNetwork}
                />
              </div>
            )}
            <Account
              useBurner={USE_BURNER_WALLET}
              address={address}
              localProvider={localProvider}
              userSigner={userSigner}
              mainnetProvider={mainnetProvider}
              price={price}
              web3Modal={web3Modal}
              loadWeb3Modal={loadWeb3Modal}
              logoutOfWeb3Modal={logoutOfWeb3Modal}
              blockExplorer={blockExplorer}
            />
          </div>
        </div>
      </Header>
      {yourLocalBalance.lte(ethers.BigNumber.from("0")) && (
        <FaucetHint localProvider={localProvider} targetNetwork={targetNetwork} address={address} />
      )}
      <NetworkDisplay
        NETWORKCHECK={NETWORKCHECK}
        localChainId={localChainId}
        selectedChainId={selectedChainId}
        targetNetwork={targetNetwork}
        logoutOfWeb3Modal={logoutOfWeb3Modal}
        USE_NETWORK_SELECTOR={USE_NETWORK_SELECTOR}
      />
      <Menu style={{ textAlign: "center", marginTop: 20 }} selectedKeys={[location.pathname]} mode="horizontal">
        <Menu.Item key="/">
          <Link to="/">App Home</Link>
        </Menu.Item>
        <Menu.Item key="/debug">
          <Link to="/debug">Debug Contracts</Link>
        </Menu.Item>
        <Menu.Item key="/hints">
          <Link to="/hints">Hints</Link>
        </Menu.Item>
        <Menu.Item key="/exampleui">
          <Link to="/exampleui">ExampleUI</Link>
        </Menu.Item>
        <Menu.Item key="/mainnetdai">
          <Link to="/mainnetdai">Mainnet DAI</Link>
        </Menu.Item>
        <Menu.Item key="/subgraph">
          <Link to="/subgraph">Subgraph</Link>
        </Menu.Item>
      </Menu> */}

      <Switch>
        <Route exact path="/">
          {/* pass in any web3 props to this Home component. For example, yourLocalBalance */}
          {/* <Home yourLocalBalance={yourLocalBalance} readContracts={readContracts} /> */}
          <APP2 data={data} />
        </Route>
        <Route exact path="/debug">
          {/*
                🎛 this scaffolding is full of commonly used components
                this <Contract/> component will automatically parse your ABI
                and give you a form to interact with it locally
            */}

          {/* <Contract
            name="YourContract"
            price={price}
            signer={userSigner}
            provider={localProvider}
            address={address}
            blockExplorer={blockExplorer}
            contractConfig={contractConfig}
          /> */}
        </Route>
        <Route path="/hints">
          {/* <Hints
            address={address}
            yourLocalBalance={yourLocalBalance}
            mainnetProvider={mainnetProvider}
            price={price}
          /> */}
        </Route>
        <Route path="/UserWallet">
          <UserWallet />
        </Route>
        <Route path="/UserSignIn">
          <UserSignIn data={data} />
        </Route>
        <Route path="/UserCalendars">
          <UserCalendars data={data} />
        </Route>
        <Route path="/BindAccount">
          <BindAccount data={data} />
        </Route>
        <Route path="/UserFollow">
          <UserFollow data={data} />
        </Route>
        <Route path="/UserInvite">
          <UserInvite data={data} />
        </Route>
        <Route path="/Mynodes">
          <Mynodes data={data} />
        </Route>
        <Route path="/Nodes">
          <Nodes data={data} />
        </Route>
        <Route path="/BuyNodes">
          <BuyNodes data={data} />
        </Route>
        <Route path="/Chat">
          <Chat data={data} />
        </Route>
        <Route path="/Points">
          <Points data={data} />
        </Route>
        <Route path="/Publicoffering">
          <Publicoffering data={data} />
        </Route>
        <Route path="/Mining">
          <Mining data={data} />
        </Route>

        {/* <Route path="/exampleui">
          <ExampleUI
            address={address}
            userSigner={userSigner}
            mainnetProvider={mainnetProvider}
            localProvider={localProvider}
            yourLocalBalance={yourLocalBalance}
            price={price}
            tx={tx}
            writeContracts={writeContracts}
            readContracts={readContracts}
            purpose={purpose}
          />
        </Route> */}
        <Route path="/mainnetdai">
          <Contract
            name="DAI"
            customContract={
              mainnetContracts &&
              mainnetContracts.contracts &&
              mainnetContracts.contracts.DAI
            }
            signer={userSigner}
            provider={mainnetProvider}
            address={address}
            blockExplorer="https://etherscan.io/"
            contractConfig={contractConfig}
            chainId={1}
          />
          {/*
            <Contract
              name="UNI"
              customContract={mainnetContracts && mainnetContracts.contracts && mainnetContracts.contracts.UNI}
              signer={userSigner}
              provider={mainnetProvider}
              address={address}
              blockExplorer="https://etherscan.io/"
            />
            */}
        </Route>
        {/* <Route path="/subgraph">
          <Subgraph
            subgraphUri={props.subgraphUri}
            tx={tx}
            writeContracts={writeContracts}
            mainnetProvider={mainnetProvider}
          />
        </Route> */}
      </Switch>

      {/* <ThemeSwitch /> */}

      {/* 🗺 Extra UI like gas price, eth price, faucet, and support: */}
      {/* <div style={{ position: "fixed", textAlign: "left", left: 0, bottom: 20, padding: 10 }}>
        <Row align="middle" gutter={[4, 4]}>
          <Col span={8}>
            <Ramp price={price} address={address} networks={NETWORKS} />
          </Col>

          <Col span={8} style={{ textAlign: "center", opacity: 0.8 }}>
            <GasGauge gasPrice={gasPrice} />
          </Col>
          <Col span={8} style={{ textAlign: "center", opacity: 1 }}>
            <Button
              onClick={() => {
                window.open("https://t.me/joinchat/KByvmRe5wkR-8F_zz6AjpA");
              }}
              size="large"
              shape="round"
            >
              <span style={{ marginRight: 8 }} role="img" aria-label="support">
                💬
              </span>
              Support
            </Button>
          </Col>
        </Row>

        <Row align="middle" gutter={[4, 4]}>
          <Col span={24}>
            {
              faucetAvailable ? (
                <Faucet localProvider={localProvider} price={price} ensProvider={mainnetProvider} />
              ) : (
                ""
              )
            }
          </Col>
        </Row>
      </div> */}
    </div>
  );
}

export default App;
